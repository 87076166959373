import { InfoModals } from "./InfoModals";

export const en = {
    'exit': 'Log out',
    'copy': {
        'id_copied': 'id copied',
        'id_not_copied': 'id not copied',
        'notification': 'Notification',
        'token_copied': 'Token copied',
        'addresses_copied': 'Address copied',
        'addresses_not_copied': 'Addresses not copied',
        'the_name_of_the_key_copied': 'The name of the key copied',
        'the_name_of_the_key_not_copied': 'The name of the key not copied',
        'the_public_key_copied': 'The public key copied',
        'the_public_key_not_copied': 'The public key not copied',
        'the_private_key_copied': 'Private key copied',
        'the_private_key_not_copied': 'The private key not copied',
        'api_key_successfully_updated': 'Api key successfully updated',
        'failed_to_update_api_key': 'Failed to update api key',
        'order_title': 'Order title  copied',
        'order_title_failed': 'Order title not copied',
        'transaction_hash': 'Transaction copied',
        'link_copied': 'Link copied',
        'transaction_hash_failed': 'Transaction hash not copied',
        'edit': 'Edit',
        'deleteKey': 'Remove',
    },
    'navigation': {
        'main': 'Main',
        'pinned': 'Pinned',
        'advance_balances': 'Advance balances',
        'addresses': 'Wallets',
        'addresses_sub': 'Pay-in',
        'change': 'Swap',
        'organizations': 'Organizations',
        'subscriptions': 'Recurrents',
        'payment-bundle': 'Payment bundles',
        'payment-address': 'Payment addresses',
        'orders': 'Orders',
        'transactions': 'Transactions',
        'payments': 'Payments',
        'widgets': 'Widgets',
        'widgetsManage': 'Manage widget',
        'withdrawal': 'Withdrawal',
        'info': 'Information',
        'api_keys': 'Api keys',
        'api_docs': 'Api docs',
        'donations': 'Donations',
        'payout_wallet': 'Payout',
        'invoice': 'Invoices',
        'bridge': 'Bridge',
        'bank': 'Bank',
        'sepa-swift': 'SEPA/SWIFT',
        'cross-chain': 'Cross chain',
        'cross-chain-history': 'Cross chain history',
        'swap': 'Swap',
        'swap-history': 'Swap History',
        'business-wallet': 'Business wallet',
        'wallet': 'Wallet',
        'referral': 'Referral program',
        'support': 'Support',
        'integrations': 'Integrations',
        'address-book': 'Address book',
        'orphan': 'Orphan transactions',
        'personal-addresses': 'Personal addresses',
        'webhooks': 'Webhooks',
        'new': 'NEW',
        'where-found': 'Where found:',
        'accounts': 'Accounts',
        'integration-settings': 'Integration settings'
    },
    'pages-description': {
        'pay-in-description': 'Wallet for receiving payments for orders. It is possible to withdraw profits and make internal transactions to replenish the advance balance.',
        'business-wallet-description': 'Addresses for receiving free deposits without orders and invoices.',
        'address-book-description': 'Convenient storage of external addresses for quick withdrawal.',
        'crosschain-description': 'Blockchain bridge for tokens on your addresses between networks.',
        'transactions-description': 'All your transactions inside the service with complete information on each movement of funds.',
        'invoices-description': 'Create an invoice in a convenient currency and track invoice payments.',
        'orders-description': 'Creating orders and tracking order payments.'
    },
    'settings-modal': {
        'title': 'Business account',
        'dark-theme': 'Dark theme',
        'settings': 'Settings',
        'aml-policy': 'AML policy',
        'business-settings': 'Business settings',
        'logout': 'Log Out',
        'language': 'Language',
        'collect': 'Collecting profits on wallets'
    },
    'authorization': {
        'login-title': 'Authorization',
        'restore-password-title': 'Restore password',
        'execute': 'Confirm',
        'registration-title': 'Registration',
        'verif-title': 'Two-factor authentication (2FA)',
        'verification-email': 'Email verification',
        'password': 'Password',
        'repeat-password': 'Repeat password',
        'email-confirm': 'Email confirmation',
        'email-description': 'A link to confirm registration has been sent to the mail. The email will come from {email}',
        'dont-have-acc': 'Don`t you have registered yet?',
        'have-acc': 'Have an account?',
        'ref-code': 'Referral code',
        'username': 'Username',
        'email': 'E-mail',
        'incorrect-data': 'Incorrect email address or password',
        'invalid-email': 'Incorrect email',
        'invalid-passwords': 'Incorrect password',
        'invalid-password-not-match': 'Passwords don`t match',
        'user-existing': 'User is existing',
        'send': 'Send',
        'logout': 'Log out',
        'enter-code': 'Enter code',
        'verification-description': ' Enter the one time code in your authenticator app.',
        'code-not-entered': 'The code is not entered',
        'code-not-valid': 'The code is not valid',
        'code': 'Code',
        'invalid-password': 'Invalid password',
        'invalid-repassword': 'Passwords don`t match',
        'invalid-link': 'Invalid link',
        'return-to-login': 'Return to login page',
        'minimum-characters': 'Minimum 6 characters',
        'uppercase-restriction': 'One uppercase and lowercase letter a…Z',
        'spec-char': 'At least one special character !@#%^&*}{[]',
        'number-char': 'At least one number 0...9',
        'forgot': 'Forgot your password?',
        'restore-title': 'Password reset',
        'restore-desc': 'Are you sure you want to reset the current password?',
        'restore-subDesc': 'You will need to contact support to recover your password.',
        'close': 'Close',
    },
    'dashboard': {
        'main': 'Main',
        'balance': 'Balance',
        'balances': 'Balances',
        'fold': 'Fold',
        'orders': 'Orders',
        'there_is_nothing': 'There is nothing',
        'period': 'Period',
        'successful_transactions': 'Successful transactions',
        'type': 'Type',
        'quantity': 'Quantity',
        'amount': 'Amount',
        'currency': 'Currency',
        'deposits': 'Deposit',
        'withdrawals': 'Withdrawals',
        'orders_decl': 'Orders',
        'created_decl': 'Created',
        'executed_decl': 'Completed',
        'created': 'Created',
        'completed': 'Completed',
        'incoming_transactions': 'Incoming transactions',
        'outgoing_transactions': 'Outgoing transactions',
        'completed_withdrawal_requests': 'Completed withdrawal requests',
        'enable_two-factor_authentication': 'Enable two-factor authentication',
        'enable': 'Enable',
    },
    'advance_balances': {
        'title': 'Advance balances',
        'subtitle': 'Advance balance is used for automatic debiting of commission for all financial transactions on the service, as well as for the network fee',
        'account_replenished_successfully': 'Account {start}****{end} was replenished by {amount} {ticker}',
        'description': 'The advance balance is used for automatic deduction of commission for all financial transactions on the service, as well as for network fees.',
        'currency': 'Currency',
        'get_an_address': 'Go to payment',
        'currency-and-network-label': 'Currency and network',
        'currency-and-network-sublabel': 'Your coins will be transferred at the exchange rate',
        'currency-and-network-placeholder': 'Select currency and network',
        'currency-and-network-search': 'Search',
        'balance': 'Balance',
        'no_data_available': 'No data available',
        'deposit': 'Deposit',
        'network': 'Network',
        'select_network': 'Select network',
        'address_for_replenishment': 'Address for replenishment:',
        'time': 'Time until the address expires:',
        'cancel': 'Close',
        'replenishment': 'Balance replenishment',
        'replenishment_subtitle': 'To continue, you need to select the currency and network',
        'unknown': 'An unknown coin has been selected for replenishment',
        'payments': 'Accepting payments in this coin is currently impossible',
        'need_to_select': 'To continue, you need to select a network',
        'creating_an_address': 'Creating an address',
        'balance_history': 'Balance history',
        'history_of_actions': 'History of actions.',
        'period': 'Period',
        'select_dates': 'Select dates',
        'Replenishment': 'Replenishment',
        'withdrawal': 'Withdrawal',
        'withdrawal_fee': 'Withdrawal fee',
        'creating_an_order': 'Order creation fee',
        'deposit_by_order': 'Accepting payments',
        'wallet_withdrawal_fee': 'Withdrawal from wallet',
        'recurrent_withdrawal_fee': 'Withdrawal from recurrent',
        'personal_withdrawal_fee': 'Personal address withdrawal',
        'payout_withdrawal_fee': 'Withdrawal from pay-out',
        'wallet_deposit_fee': 'Wallet deposit',
        "collect_withdrawal_fee": "Collect withdrawal",
        "collect_withdrawal": "Collect withdrawal",
        'recurrent_deposit_fee': 'Recurrent deposit',
        'personal_deposit_fee': 'Personal address deposit',
        'payout_deposit_fee': 'Deposit pay-out',
        'payout_auto_swap': 'Payout + auto swap',
        'payout_auto_swap_fee': 'Payout auto swap fee',
        'deposit_by_order_fee': 'Order deposit fee',
        'payout_auto_swap_fee_correction_decr': 'Correction of the auto swap fee',
        'payout_auto_swap_fee_correction_incr': 'Correction of the auto swap fee',
        'deposit_by_wallet_fee': 'Wallet deposit fee',
        'exchange_internal': 'Swap',
        'exchange_internal_fee': 'Swap',
        'exchange_auto_fee': 'Exchange auto fee',
        'transfer': 'Internal transfer',
        'transfer_fee': 'Internal transfer fee',
        'kyt_address_fee': 'Address risks',
        'write-downs': 'Write-downs',
        'cancellation_of_the_operation': 'Cancellation of the operation',
        'refund': 'Refund',
        'bonus_accrual': 'Bonus accrual',
        'from_address': 'From address',
        'link_to_order': 'Link to the order',
        'type_operation': 'Type of operation',
        'select_type': 'Select type',
        'amount_received': 'Amount received',
        'amount': 'Amount',
        'order': 'Order',
        'type_of_operation': 'Type of operation',
        'price': 'Price',
        'link_to_withdrawal': 'Link to the withdrawal',
        'tariff_description': 'Commission for the executed transaction on the created order',
        'select_currency': 'Select currency',
        'will_be_converted': 'Your coins will be converted to «{currencyName}» at the exchange rate.',
        'expire': 'Address expires in',
        'link': 'Link',
        'btn-cancel': 'Cancel',
        'btn-close': 'Close',
        'btn-continue': 'Continue',
        'btn_get-an-address': 'To payment',
        'replenishment_method': 'Method of deposit',
        'external_wallet': 'External wallet',
        'internal_pay-in-balance': 'Pay-in balance',
        'internal_business-wallet': 'Business Wallet',
        'internal_recurrent-address': 'Recurrent address',
        'spent_period': 'Spent per period',
        'one_day': '24 hours',
        'seven_day': '7 days',
        'thirty_day': '30 days',
        'all_period': 'All period',
        'choose-deposit-method': 'Choose a deposit method',
        'bridge_internal_fee': 'Blockchain bridge',
        'bridge_external_fee': 'Blockchain bridge API',
        'payment-address': 'Payment Address',
        'kyt-transaction-fee': 'Transaction risks',
        'kyt-withdrawal-address-fee': 'Withdrawal risks',
        'kyt-transaction': 'Checking transaction risks',
        'kyt-withdrawal-address':'Checking withdrawal risks',
        'exchange-auto': 'Swap API',
        'exchange-auto-fee': 'Swap API fee',
        'orphan-deposit-withdrawal': 'Orphan deposit withdrawal',
        'gas-deposit': 'Gas replenishment',
        'orphan-deposit-withdrawal-fee': 'Orphan deposit withdrawal fee',
        'orphan-deposit': 'Orphan deposit',
        'timer': {
            'awaiting-payment': 'Awaiting payment',
            'payment-lifetime-has-expired': 'The payment lifetime has expired'
        },
        'btn-label': {
            'top-up': 'Top up',
            'commissions': {
                'my-commissions': 'My commissions',
                'banner': {
                    'view': 'View'
                },
                'contact-us-banner': {
                    'contact-us': 'Contact us'
                }
            }
        },
        'info-card': {
          'balance': 'Balance',
          'spend': 'Spent per period',
          'spend-period-select-labels': {
              'one-day': '24 hours',
              'seven-day': '7 days',
              'thirty-day': '30 days',
              'all-period': 'All period'
          }
        },
        'commissions': {
            'banner': {
                'title': 'My commissions',
                'description': 'Find out more about your commissions on the service'
            },
            'contact-us-banner': {
                'title': 'Adapt commissions to your business',
                'description': 'At the moment, commissions are set for a transaction turnover of $ 1000 per month. If your company carries out larger operations, contact us to adjust the commissions.',
            },
            'groups': {
                'pay-in': 'Pay-in',
                'business-wallet': 'Business wallet',
                'pay-out': 'Pay-out',
                'personal-addresses': 'Personal addresses',
                'recurrents': 'Recurrents',
                'internal': 'Internal transfers',
                'exchange': 'Exchange',
                'kyt': 'KYT',
                'orphan': 'Orphan transactions'
            },
            'actions': {
                'creating_an_order': 'Order creation fee',
                'deposit_by_order': 'Accepting payments',
                'transfer': 'Internal transfer',
                'transfer_fee': 'Internal transfer fee',
                'wallet_withdrawal_fee': 'Withdrawal from wallet',
                'recurrent_withdrawal_fee': 'Withdrawal from recurrent',
                'personal_withdrawal_fee': 'Personal address withdrawal',
                'payout_withdrawal_fee': 'Withdrawal from pay-out',
                'wallet_deposit_fee': 'Wallet deposit',
                "collect_withdrawal_fee": "Collect withdrawal",
                "collect_withdrawal": "Collect withdrawal",
                'recurrent_deposit_fee': 'Recurrent deposit',
                'personal_deposit_fee': 'Personal address deposit',
                'payout_deposit_fee': 'Deposit pay-out',
                'deposit_by_order_fee': 'Order deposit fee',
                'deposit_by_wallet_fee': 'Wallet deposit fee',
                'exchange_internal': 'Swap',
                'exchange_internal_fee': 'Swap',
                'bridge_internal_fee': 'Blockchain bridge',
                'bridge_external_fee': 'Blockchain bridge API',
                'kyt_transaction_fee': 'Transaction risks',
                'kyt_withdrawal_address_fee': 'Withdrawal risks',
                'kyt_transaction': 'Checking transaction risks',
                'kyt_withdrawal_address':'Checking withdrawal risks',
                'kyt_address': 'Checking address risks',
                'exchange_auto': 'Swap API',
                'exchange_auto_fee': 'Swap API',
                'orphan_deposit_withdrawal': 'Orphan deposit withdrawal',
                'sepa-withdrawal': 'Withdraw SEPA',
                'sepa-deposit': 'Deposit SEPA',
                'fiat-exchange': 'Fiat/Crypto exchange',
                'payout_auto_swap': 'Payout + auto swap',
                'unknown_tariff': 'Unknown tariff'
            }
        },
        'history': {
            'filters': {
                'operation_type': {
                    'placeholder': 'Operation type',
                    'types': {}
                }
            },
            'table': {
                'cols': {
                    'id': 'id',
                    'date': 'Date',
                    'type': 'Type',
                    'link': 'Link',
                    'amount': 'Transaction amount',
                    'currency-and-network': 'Currency/Network',
                    'balance': 'Balance',
                },
                'rows': {
                    'link': 'Details link',
                }
            }
        }
    },
    'swap': {
        'title': 'Swap',
        'subtitle': 'Exchange currencies and lock in profits manually or automatically.',
        'divider': {
            'exchange-rates': 'Exchange rate'
        },
        'currency-network-select': {
            'from': {
                'label': 'Choose your currency and network from'
            },
            'to': {
                'label': 'Choose your currency and network to'
            }
        },
        'address_select': {
            'label_from': 'Choose your address from',
            'label_to': 'Choose your address to',
            'placeholder': 'Address',
            'options_list': {
                'title': {
                    'choose_wallet': 'Choose wallet'
                },
                'sorting': {
                    'label': 'Sort by',
                    'fields': {
                        'amount': 'Amount'
                    }
                },
                'back_btn': {
                    'label': 'Back to choose wallet'
                },
                'empty': {
                    'addresses': "No addresses",
                    'wallets': "No wallets"
                }
            }
        },
        'amount_input': {
            'label': 'Enter amount',
            'available': 'Available balance',
            'minimum': 'Minimum',
            'maximum': 'Maximum',
        },
        'amount_to_input': {
            'label': 'You recieve',
        },
        'modals': {
            'execute': {
                'title': 'Swap confirmation',
                'currency': 'Currency',
                'network': 'Network',
                'amount': 'Amount',
                'address': 'Address',
                'source': 'Commission source',
                'advance_balance': 'Advance balance',
                'swap_amount': 'Swap amount',
                'exchange_rate': 'Exchange rate',
                'service_commission': 'Service commission amount',
                'network_commission': 'Network commission amount',
                'confirm': 'Confirm',
                'cancel_btn': 'Cancel',
                'you_receive': 'You will receive'
            },
            'create_address': {
                'title': 'Adding an address',
                'address': 'Address',
                'network': 'Network',
                'currency': 'Currency',
                'alias': 'Alias',
                'alias_placeholder': 'Enter a text',
                'comment': 'Comment',
                'comment_placeholder': 'Enter a comment',
                'cancel_btn': 'Cancel',
                'save_btn': 'Save',
                'warning': 'You do not have an address in the selected currency and network. You need to create an address.',
                'warning_btn': 'Create an address',
                'error': 'Error'
            },
            'success': {
                'body-text': 'Exchange completed'
            }
        },
        'footer': {
            'execute_btn': 'Execute',
            'commission_source': {
                'service': 'Source of service commission payment',
                'network': 'Source of network commission payment',
            },
            'advance_balance': 'Advance balance',
            'swap_amount': 'Swap amount',
            'service_commission': 'Service commission',
            'network_commission': 'Network commission'
        },
        'history': {
            'filters': 'Filters',
            'date': 'Date',
            'copy': {
                'id_copied': 'Id copied',
                'id_not_copied': 'Id not copied',
            },
            'title': 'Swap history',
            'currency_from_placeholder': 'Currency from',
            'currency_to_placeholder': 'Currency to',
            'network_from_placeholder': 'Sending network',
            'network_to_placeholder': 'Receiving network',
            'period': 'Period',
            'period_placeholder': 'Choose period',
            'period_select_btn': 'Choose dates',
            'status_placeholder': 'Choose status',
            'statuses': {
                "CREATED": 'Created',
                "PENDING": 'Pending',
                "ERROR": 'Error',
                "REJECTED": 'Rejected',
                "PROCESSED": 'Processed'
            },
            'table': {
                'headers': {
                    'id': 'ID',
                    'amount': 'Sum',
                    'date': 'Date',
                    'state': 'Status',
                    'currencyFrom': 'Currency from',
                    'networkFrom': 'Network from',
                    'addressFrom': 'Address from',
                    'amountFrom': 'Amount from',
                    'currencyTo': 'Currency to',
                    'networkTo': 'Network to',
                    'addressTo': 'Address to',
                    'amountTo': 'Amount to',
                    'networkCommission': 'Network commission',
                    'serviceCommission': 'Service commission'
                }
            }
        }

    },
    'bridge': {
        'title': 'Blockchain bridge',
        'description': 'Blockchain bridge for tokens on your addresses between networks',
        'bridge_source': {
            'label': 'Bridge source',
            'business_wallet': 'Business wallet',
            'payin_wallet': 'Payin wallet',
        },
        'address_select': {
            'label_from': 'Choose your address from',
            'label_to': 'Choose your address to',
            'placeholder': 'Address',
            'options_list': {
                'title': {
                    'choose_wallet': 'Choose wallet'
                },
                'sorting': {
                    'label': 'Sort by',
                    'fields': {
                        'amount': 'Amount'
                    }
                },
                'back_btn': {
                    'label': 'Back to choose wallet'
                },
                'empty': {
                    'addresses': "No addresses",
                    'wallets': "No wallets"
                }
            }
        },
        'amount_input': {
            'label': 'Enter amount',
            'available': 'Available balance',
            'minimum': 'Minimum',
            'maximum': 'Maximum',
        },
        'currency_select': {
            'label': 'Choose currency',
            'placeholder': 'Currency',
        },
        'network_select': {
            'label': 'Choose network',
            'placeholder': 'Network',
        },
        'amount_to_input': {
            'label': 'You recieve',
        },
        'footer': {
            'execute_btn': 'Execute',
            'source': 'Source of commission payment',
            'advance_balance': 'Advance balance',
            'service_commission': 'Service commission',
            'network_commission': 'Network commission'
        },
        'modals': {
            'execute': {
                'title': 'Blockchain bridge confirmation',
                'currency': 'Currency',
                'network': 'Network',
                'amount': 'Amount',
                'address': 'Address',
                'source': 'Commission source',
                'advance_balance': 'Advance balance',
                'service_commission': 'Service commission amount',
                'network_commission': 'Network commission amount',
                'confirm': 'Confirm',
                'cancel_btn': 'Cancel',
                'you_receive': 'You will receive'
            },
            'create_address': {
                'title': 'Adding an address',
                'address': 'Address',
                'network': 'Network',
                'currency': 'Currency',
                'alias': 'Alias',
                'alias_placeholder': 'Enter a text',
                'comment': 'Comment',
                'comment_placeholder': 'Enter a comment',
                'cancel_btn': 'Cancel',
                'save_btn': 'Save',
                'warning': 'You do not have an address in the selected currency and network. You need to create an address.',
                'warning_btn': 'Create an address',
                'error': 'Error'
            },
            'success': {
                'body-text': 'Blockchain bridge launched'
            }
        },
        'errors': {
            'advance_balance_enough': 'Insufficient funds on advance balance'
        },
        'history': {
            'filters': 'Filters',
            'date': 'Date',
            'copy': {
                'id_copied': 'Id copied',
                'id_not_copied': 'Id not copied',
            },
            'title': 'Blockchain bridge history',
            'description': 'Blockchain bridge for tokens on your addresses between networks',
            'currency': 'Сurrency',
            'currency_placeholder': 'Choose currency',
            'network_from_placeholder': 'Sending network',
            'network_to_placeholder': 'Receiving network',
            'period': 'Period',
            'period_placeholder': 'Choose period',
            'period_select_btn': 'Choose dates',
            'status_placeholder': 'Choose status',
            'statuses': {
                "CREATED": 'Created',
                "PENDING": 'Pending',
                "ERROR": 'Error',
                "REJECTED": 'Rejected',
                "PROCESSED": 'Processed'
            },
            'table': {
                'headers': {
                    'id': 'ID',
                    'amount': 'Sum',
                    'date': 'Date',
                    'state': 'Status',
                    'currency': 'Сurrency',
                    'networkFrom': 'Network from',
                    'addressFrom': 'Address from',
                    'networkTo': 'Network to',
                    'addressTo': 'Address to',
                    'networkCommission': 'Network commission',
                    'serviceCommission': 'Service commission'
                }
            }
        }
    },
    'addresses': {
        'title': 'Balances',
        'button_transfer': 'Transfer',
        'description': 'Wallet for receiving payments for orders. It is possible to withdraw profits and make internal transactions to replenish the advance balance.',
        'currency_search': 'Currency Search',
        'zero_balances': 'Hide zero balances',
        'zero_balances_show': 'Show zero balances',
        'vacuum': 'Vacuum',
        'currency': 'Currency',
        'address': 'Address',
        'network': 'Network',
        'balance': 'Balance',
        'withdraw': 'Withdraw',
        'deposit': 'Deposit',
        'no_data_available': 'No data available',
        'search_address': 'Search by address...',
        card: {
            title: {
                currency: 'Currency:',
                network: 'Networks:',
                addresses: 'Addresses:',
                address: 'Address:',
                balance: 'Total balance:',
                singleBalance: 'Balance:',
            },
        },
    },
    'orders': {
        'title': 'Orders',
        'lifetime': 'Order lifetime',
        'lifetimeMinValueMinute': 'from 30 minutes',
        'lifetimeMinValueHour': 'from 0 hours | from {n} hour | from {n} hours',
        'lifetimeMaxValueHour': 'to 0 hours | to {n} hour | to {n} hours',
        'description': 'Description',
        'no_data_available': 'No data available',
        'new_order': 'New order',
        'status': 'Status',
        'select_the_status': 'Select status',
        'with_errors': 'With errors',
        'select_currencies': 'Select currency',
        'currencies': 'Currency',
        'network': 'Network',
        'select_network': 'Select network',
        'period': 'Period',
        'select_dates': 'Select dates',
        'order': 'Order',
        'currency': 'Currency',
        'received_amount': 'Received amount',
        'amount': 'Amount',
        'date_of_creation': 'Date of creation',
        'read_more': 'Read more',
        'validity': 'Expiration date and time',
        'contract': 'Contract address',
        'recipient-address': 'Address',
        'advance-balance': 'Advance balance',
        'type-in': 'Deposit',
        'type-out': 'Withdraw',
        'info_title': 'Order details',
        'error-webhook': 'Error webhook',
        'success-webhook': 'Success webhook',
        'return-url': 'Return URL',
        'address-tag': 'Payment address tag',
        'link': 'Link to payment',
        'order-number': 'Order №',
        'transaction-details': 'Transaction details',
        'open': 'Open',
        'payment': 'Payment for the order',
        'error': 'Error',
        'refund': 'Refund',
        'init': 'Init',
        'pending': 'Pending',
        'partial': 'Partial',
        'processed': 'Processed',
        'expired': 'Expired',
        'rejected': 'Rejected',
        'overpaid': 'Overpaid',
        'received-order-amount': 'Received by order',
        'more': 'More details',
        'alias': 'Alias'
    },
    'api_documentation': {
        'title': 'Api documentation',
        'description': '{productName} API documentation.',
    },
    'api_keys': {
        'title': 'Api keys',
        'description': 'Multiple keys can be added.',
        'no_data_available': 'No data available',
        'name': 'Name',
        'public_key': 'Public key',
        'private_key': 'Private key',
        'add_api_key': 'Add api key',
        'search': 'Search by name...',
        'api_key_created': 'Api key created',
        'creating_an_api_key': 'Creating an api key',
        'editing_an_api_key': 'Editing an api key',
        'name_of_the_api_key': 'Name of the api key (alias)',
        'ip_addresses': 'IPV4 addresses',
        'ipv6_addresses': 'IPV6 addresses',
        'ip_addresses_placeholder': 'Enter IPV4 addresses separated by a space',
        'ipv6_addresses_placeholder': 'Enter IPV6 addresses separated by a space',
        'ip_invalid': 'Invalid IP address format',
        'ip_exist': 'The IP address input field should not be empty',
        'alias_error': 'Enter alias',
        'be_sure_to_keep': 'Be sure to keep your private key!',
        'creating_an_api_keys': 'Creating an api keys',
        'cancel': 'Cancel',
        'create': 'Create',
        'edit': 'Edit',
        'close': 'Close',
        'enter_a_new_api_key_name': 'Enter api key name',
        'save': 'Save',
        'successful_deletion': 'Successful deletion',
        'deleting_an_api_key': 'Deleting an api key',
        'are_you_sure': 'Are you sure you want to delete the api key?',
        'delete': 'Delete',
        'full': 'Full rights',
        'none': 'Read only',
        'partial': 'Partial rights',
        'list-of-available': 'List of available API keys',
        'choose-api-keys': 'Choose API key'
    },
    'api_keys-create': {
        'withdrawals': 'Withdrawals',
        'orders': 'Orders',
        'invoices': 'Invoices',
        'personal_addresses': 'Personal addresses',
        'swaps': 'Swaps',
        'advanced_balance': 'Advanced balance',
        'orphan': 'Orphan',
        'recurrent': 'Recurrent',
        'address_book': 'Address book',
        'bridge': 'Bridge',
        'view': 'View',
        'create': 'Create',
        'update': 'Update',
        'withdraw': 'Withdraw',
        'remove': 'Remove',
        'payment': 'Payment',
        'full-rights': 'Full rights',
        'partial-rights': 'Partial rights',
        'read-only': 'Read only'
    },
    'api-keys-zero': {
        'title': 'Здесь будут отображаться ваши API ключи',
        'subtitle': 'В данном разделе вы сможете создавать, редактировать и удалять ваши API ключи.',
        'title-desc': 'Обратите внимание: При создании ключа, приватный ключ будет показан только один раз. Сохраните его в надежном месте.',
        'create-title': 'Создать API ключи',
        'create-desc': 'Создайте API ключ для работы с сервисом',
    },
    'information': {
        'title': 'Information',
        'description': 'Commission for transactions in blockchain networks.',
        'currency': 'Currency',
        'network': 'Network',
        'commission': 'Commission',
        'commission-source': 'Commission source',
        'type': 'Type',
        'native': 'Native',
        'token': 'Token',
        'withdrawal-amount': 'Withdrawal amount',
        'advance-balance': 'Advance balance',
    },
    'transactions': {
        'title': 'Transactions',
        'description': 'All of your transactions within the service are recorded with complete information for each movement of funds.',
        'no_data_available': 'No data available',
        'type': 'Type',
        'method': 'Creation method',
        'basis': 'Basis',
        'source': 'Source',
        'address_from': 'Address from',
        'address_to': 'Address to',
        'CurrencyNetwork': 'Currency/Network',
        'debitCredit': 'Debit/Credit',
        'tx_hash': 'TX_hash',
        'sum': {
            debit: 'Debit',
            credit: 'Credit',
        },
        'basis_enum': {
            'order': 'Order',
            'withdrawal': 'Withdrawal',
            'payment': 'Payment',
            'refund': 'Refund',
            'deposit': 'Deposit',
            'transfer': 'Transfer',
            'kyt': 'KYT',
            'exchange-auto': 'Swap API',
            'exchange-internal': 'Swap',
            'exchange-internal-fee': 'Swap fee',
            'collecting': 'Collecting profits',
            'commission_withdrawal': 'Withdrawal fee',
            'payout_auto_swap': 'Payout + auto swap',
            'payout_auto_swap_fee': 'Payout auto swap fee'
        },
        'source_enum': {
            'internal': 'Internal transfer',
            'external': 'External transfer',
        },
        'role': 'Role',
        'status': 'Status',
        'date_of_creation': 'Date',
        'date_of_creation_order': 'Created at',
        'network': 'Network',
        'write-off_currency': 'Write-off currency',
        'replenishment_currency': 'Replenishment currency',
        'coin': 'Coin',
        'order_id': 'Order id',
        'write-off_amount': 'Write-off amount',
        'deposit_amount': 'Deposit amount',
        'commission': 'Commission',
        'deposit': 'Advanced balance replenishment',
        'advanced_balance_refund': 'Advanced balance refund',
        'exchange': 'Currency exchange',
        'exchange_internal': 'Swap',
        'exchange_internal_fee': 'Swap fee',
        'network_fee': 'Network commission',
        'orfan_deposit': 'Enrollment',
        'orfan_withdrawal': 'Withdrawal',
        'commission_create_order': 'Order creation fee',
        'commission_execute_order': 'Transaction order fee',
        'commission_wallet_deposit': 'Wallet deposit fee',
        'commission_payout_deposit': 'Payout balance deposit fee',
        'commission_recurrent_deposit': 'Recurrent address deposit fee',
        'commission_personal_deposit': 'Personal address deposit fee',
        'commission_wallet_withdrawal': 'Wallet withdrawal fee',
        'commission_payout_withdrawal': 'Payout balance withdrawal fee',
        'commission_recurrent_withdrawal': 'Recurrent address withdrawal fee',
        'deposit_collect': 'Deposit collect',
        'commission_personal_withdrawal': 'Personal address withdrawal fee',
        'order_transaction': 'Order transaction',
        'deposit_payout_balance': 'Payout balance deposit',
        'deposit_wallet': 'Wallet deposit',
        'deposit_recurrent': 'Recurrent address deposit',
        'deposit_personal': 'Personal address deposit',
        'transfer': 'Transfer',
        'commission-transfer': 'Transfer fee',
        'commission_withdrawal': 'Withdrawal commission',
        'partners': 'Affiliate Program',
        'referrals': 'Referral program',
        'rolling_reserve': 'Rolling Reserve',
        'withdrawal': 'Withdrawal',
        'init': 'Init',
        'error': 'Error',
        'pending': 'Pending',
        'processed': 'Processed',
        'executed': 'Executed',
        'rejected': 'Rejected',
        'expired': 'Expired',
        'refund': 'Refund',
        'overpaid': 'Overpaid',
        'partial': 'Partial payment',
        'transaction_type': 'Transaction type',
        'search-address-id': 'Search by address or hash...',
        'search': 'Search',
        'select_the_transaction_type': 'Select the transaction type',
        'debiting_currencies': 'Currency',
        'select_the_debiting_currencies': 'Select currency',
        'replenishment_currencies': 'Replenishment currency',
        'select_the_replenishment_currencies': 'Select the replenishment currency',
        'select_network': 'Network...',
        'period': 'Period',
        'method_enum': {
            'web': 'Web',
            'api': 'API',
            'pre_checkout': 'Donation',
        },
        transfer_type: {
            pay_in: 'Pay in',
            pay_out: 'Pay out',
            advance: 'Advance Balance',
        },
        'select_dates': 'Select dates',
        'transaction_type_enum': {
            'commission': 'commission',
            'network_fee': 'network fee',
            'deposit': 'deposit',
            'withdrawal': 'withdrawal',
            'exchange': 'exchange',
            'partners': 'partners',
            'referrals': 'referrals',
            'rolling_reserve': 'rolling reserve',
        },
        empty: '-',
        'bridge_external': 'Blockchain brigde API',
        'bridge_internal': 'Blockchain bridge',
        'bridge_internal_fee': 'Blockchain bridge fee',
        'bridge_external_fee': 'Blockchain bridge api fee',
    },
    'withdrawal': {
        'title': 'Withdrawal',
        'description': 'When creating an withdrawal, you must specify the address from which the output will be made, also specify the network and the amount of output.',
        'no_data_available': 'No data available',
        'status': 'Status',
        'select_the_status': 'Select status',
        'in_processing': 'In processing',
        'completed': 'Completed',
        'error': 'Error',
        'rejected': 'Rejected',
        'pending': 'Pending',
        'platform': 'Platform',
        'select_platform': 'Select a platform',
        'select_currencies': 'Select currency',
        'currencies': 'Currency',
        'select_network': 'Select network',
        'network': 'Network',
        'period': 'Period',
        'select_dates': 'Select dates',
        'create_a_withdrawal_request': 'Create a withdrawal',
        'currency': 'Currency',
        'amount': 'Amount',
        'date_of_creation': 'Date of creation',
        'date': 'Date',
    },
    'order': {
        'title': 'Order',
        'advance_balance': 'Advance balance:',
        'description': 'Description:',
        'network': 'Network:',
        'currency': 'Currency:',
        'amount_received': 'Amount received:',
        'amount': 'Amount:',
        'payment_address': 'Receiving address:',
        'payment_address_tag': 'Payment address tag:',
        'link_for_payment': 'Link for payment:',
        'date_of_order_creation': 'Date of order creation:',
        'validity_period_of_the_order': 'Validity period of the order:',
        'transactions': 'Transactions',
        'no_data_available': 'No data available',
        'return': 'Back',
        transaction: {
            type_enum: {
                in: 'Deposit',
                out: 'Withdrawal',
            },
            currency_type_enum: {
                in: 'Deposit currency',
                out: 'Withdrawal currency',
            },
        },
        empty: '-',
    },
    'transaction': {
        'title': 'Transaction details',
        'return': 'Back',
        'type': 'Type:',
        'status': 'Status:',
        'id_in_the_system': 'ID in the system:',
        'order_id': 'Order ID:',
        'network': 'Network:',
        'search-hash': 'Search by hash...',
        'write-off_currency': 'Write-off currency:',
        'the_tag_of_the_write-off_address': 'The tag of the write-off address:',
        'write-off_address': 'Address from:',
        'write-off_amount': 'Write-off amount:',
        'replenishment_currency': 'Transfer currency:',
        'the_tag_of_the_enrollment_address': 'The tag of the enrollment address:',
        'transfer_address': 'Address to:',
        'transfer_amount': 'Transfer amount:',
        'transfer_amount_USD': 'Transfer amount in USD:',
        'transfer_amount_rate': 'Rate to USD:',
        'transfer_recipients': 'Recipients addresses:',
        'transfer_senders': 'Senders addresses:',
        'transaction_in_the_blockchain': 'Transaction in the blockchain:',
        'date_of_creation': 'Date of creation:',
        confirmations: 'Confirmations:',
        confirmations_expected: 'Confirmations expected:',
        'transfer-amount': 'Transfer amount:',
        'comment': 'Comment:'
    },
    'transaction-column-select': {
        'title': 'Column options'
    },
    'request': {
        'title': 'Withdrawal',
        'return': 'Back',
        'currency': 'Currency:',
        'network': 'Network:',
        'status': 'Status:',
        'amount': 'Amount:',
        'commission': 'Commission:',
        'commission_source': 'Commission source:',
        'sending_address': 'Receiving address:',
        'tag_of_the_sending_address': 'The tag of the sending address:',
        'date_of_application_creation': 'Date of application creation:',
        'address_balance': 'Address balance',
        'advance_balance': 'Advance balance',
        'init': 'Generated',
        'error': 'Error',
        'processed': 'Successfully',
        'rejected': 'Rejected',
        'pending': 'In processing',
        '-': '-',
        'transaction_hash': 'Transaction hash',
        'withdrawal_address': 'Withdrawal address',
    },

    'create-withdrawal-request-modal': {
        'application_created': 'The application has been created',
        'creating_application': 'Withdrawal',
        'output_created': 'Withdrawal created',
        'pending': 'Withdrawals is being processed',
        'transaction-pending': 'Transaction is pending',
        'error-title': 'Error',
        'error': 'An error occurred while creating the withdrawal, contact support',
        'the_withdrawal_is_completed': 'Transaction is pending',
        'withdrawal_rejected': 'Withdrawal rejected, contact support',
        'creating_an_application': 'Creating an application',
        'output_address': 'Withdrawal address',
        'invalid_address': 'Enter valid address',
        'select_address': 'Select an address',
        'receiving_address': 'Receiving address',
        'enter_address': 'Enter the enrollment address',
        'tag': 'Tag:',
        'enter_tag': 'Enter the tag, if there is one',
        'amount': 'Amount:',
        'enter_amount': 'Enter the withdrawal amount',
        'max': 'MAX',
        'async-title': 'The transaction is executed asynchronously. The result of the execution can be seen in the transaction history or in the address history:',
        'minimum_withdrawal': 'Minimum to the withdrawal',
        'deposit_withdrawal_addresses_match': 'The deposit and withdrawal addresses must not match',
        'advance_balance': 'Advance balance',
        'address_balance': 'Address balance',
        'commission_source': 'Commission source:',
        'commission': 'Commission:',
        'update': 'Update fees',
        'receive': 'YOU WILL RECEIVE:',
        'cancel': 'Cancel',
        'send': 'Send',
        'close': 'Close',
        'transaction_hash': 'Transaction hash:',
        'Advance_balance': 'advance balance',
        'Address_balance': 'address balance',
        'withdrawal_amount': 'Withdrawal amount',
        'available': 'Available',
        'service_commission': 'Service commission',
        'blockchain_fee': 'Blockchain fee',
        'add_btn': 'Add new address',
        'confirm': 'Send',
        'complete_processed_desc': 'Transaction submitted for confirmation in blockchain',
        'native': 'Native currency'
    },

    'payout-modal-transfer': {
        'application_created': 'The application has been created',
        'creating_application': 'Transfer',
        'output_created': 'Transfer created',
        'pending': 'Transfer is being processed',
        'error': 'An error occurred while creating the transfer, contact support',
        'the_withdrawal_is_completed': 'The transfer is completed',
        'withdrawal_rejected': 'Transfer rejected, contact support',
        'creating_an_application': 'Creating an application',
        'output_address': 'Transfer from',
        'select_address': 'Select an address',
        'receiving_address': 'Transfer to:',
        'enter_address': 'Enter the enrollment address',
        'tag': 'Tag:',
        'enter_tag': 'Enter the tag, if there is one',
        'amount': 'Amount',
        'enter_amount': 'Enter the transfer amount',
        'max': 'MAX',
        'minimum_withdrawal': 'Minimum to the transfer:',
        'current_advance_balance': 'Current advance balance',
        'advance_balance': 'Advance balance',
        'advance_balance_after': 'Advance balance after transaction',
        'address_balance': 'Address balance',
        'service-commission-amount': 'Service commission amount',
        'blockchain-commission-amount': 'Blockchain commission amount',
        'commission-source': 'Commission source',
        'commission': 'Commission',
        'update': 'Update Commissions',
        'receive': 'You will receive',
        'cancel': 'Cancel',
        'send': 'Send',
        'close': 'Close',
        'transaction_hash': 'Transaction hash:',
        'advance': 'Advance balance',
        'pay-out': 'Pay-out balance',
        'commission_source': 'Commission source:',
        'output': {
            'title-success': 'Success',
            'title-error': 'Internal transfer failed',
            'subtitle-success': 'Your internal transfer was successful',
            'subtitle-error': 'Please try again later or contact support',
        },
    },
    'create-order-modal': {
        'order': 'Order',
        'new_order': 'New order',
        'order_placeholder': 'Order №867123567142',
        'description': 'Description',
        'description_of_order': 'Description of the order',
        'currency': 'Currency',
        'network': 'Network',
        'amount': 'Amount',
        'creating_order': 'Creating an order',
        'cancel': 'Cancel',

        'create': 'Create',
        'close': 'Close',
        'hours': 'Hours',
        'minutes': 'Minutes',
        'invalid-url': 'Invalid URL',
        'enter-webhook': 'Enter webhook',
        'enter-url': 'Enter URL',
        'error-webhook': 'Error webhook',
        'success-webhook': 'Success webhook',
        'error-url': 'Return URL',
        'advanced-options': 'Advanced options',
        'order-link-label': 'Link to the order payment page',
        'copy': 'Copy',
    },
    'preview-order-in-modal': {
        'address': 'Address:',
        'currency': 'Currency:',
        'network': 'Network:',
        'amount': 'Amount:',
        'valid_until': 'Expiration date and time:',
        'link': 'Link to the order payment page',
    },
    'checkout': {
        'shop': 'Shop',
        'payment': 'Payment',
        'back_to_the_store': 'Back to the store',
        'to_make_a_payment': 'To make a payment, send «{orderCurrency}» in network «{orderAlias}» to the specified address',
        'payment_completed': 'Payment completed',
        'the_payment_is_invalid': 'The payment is invalid',
        'payment_rejected': 'Payment rejected',
        'payment_pending': 'Payment in processing',
        'payment_init': 'Payment pending',
        'lifetime_has_expired': 'The payment lifetime has expired. Do not send coins to the specified address.',
        'contact_the_store_owner': 'The payment lifetime has expired. Do not send coins to the specified address. Contact the store owner for a coin refund or to continue the payment',
        'order': 'Order:',
        'to_be_paid': 'To be paid:',
        'received': 'Received:',
        'description': 'Description:',
        'before_the_expiration_of_the_order': 'Before the expiration of the order: ',
        'network': 'Network:',
        'payment_address': 'Payment address:',
        'payment_address_not_found': 'Payment address not found',
        'tag': 'Tag:',
        'contract_address': 'Contract address:',
        'send_to_this_address_only': 'Send to this address only',
        'make_sure_the_network_is_correct': 'Make sure the network is correct:',
        'network_is_correct': 'Make sure the network is correct:',
        'contract_address_matches': 'Make sure that the contract address matches',
        'payment_page': 'Payment page',
        'the_address_was_copied': 'The address was copied',
        'the_tag_was_copied': 'The tag was copied',
        'invoice_was_successfully_paid': 'The invoice was successfully paid',
        'the_invoice_was_paid': 'The invoice has been paid in the amount of ',
        'important_info': 'Important',
        'the_contract_address_does_not_exist_on_this_network': 'the contract address does not exist on this network',
        'error': 'Error',
        'time_hours': 'hours',
        'time_minute': 'min',
        'time_second': 'sec',
        'sellers_address': 'Sellers address is verified',
        'stored_table': 'Here will be the history of incoming transactions',
        'history_table': 'History of incoming transactions',
        'full_info': 'Show full info',
        'table_date': 'Date',
        'table_sum': 'Amount',
        'table_value': 'Coin',
        'table_hash': 'tx_hash',
        'table_date_sum': 'Amount and Date',
        'payment_partially_paid': 'Payment partially paid',
        'paid_timer': 'Paid',
        'expired_timer': 'Payment has expired',
    },
    'new_checkout': {
        'details': {
            'title': 'Details',
            'order': 'Order',
            'date': 'Date',
            'description': 'Description',
        },
        'notification': 'Please only send <b>{orderCurrency}</b> tokens on the <b>{orderNetwork}</b> network to this address.',
        'payment_address': 'Payment Address',
        'verified_notification': "The seller's address has been verified",
        'paid_amount': "Paid amount",
        'timer_notifications': {
            'remaining_time': 'Remaining time to pay',
            'completed': 'Payment has been successfully completed',
            'expired_with_invoice': 'The lifetime of the order has expired, but you can go back and create a new order',
            'expired_without_invoice': 'The payment lifetime expired',
            'dont_make_transactions': "Don't make a transactions on this order!"
        },
        'statuses': {
            'not_paid': 'Not paid',
            'overpaid': 'Overpaid',
            'partially_paid': 'Partially paid',
            'fully_paid': 'Fully paid',
        },
        'history': {
            'title': 'Payments history',
            'columns': {
                'date': 'Date',
                'amount': 'Amount',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': 'All your transactions sent to the specified address will be available in this window.',
            'ok': 'OK'
        },
        'orphan': {
          'title': 'Orphan transaction',
            'description': 'One of the transactions on this order contains the wrong network or currency.',
            'contact': 'Contact technical support with ID',
        },
        'time-is-over': 'Time is over',
        'payment-successful': 'Payment successful',
        'partial-desc': 'The amount has been partially paid. the remaining amount must be paid for successful payment',
        'cancel': 'Cancel',
        'back_to_shop': 'Back to shop',
        'back_to_invoice': 'Back to invoice',
        'choose_another_currency': 'Choose another currency',
        'you_can_pay': 'You will be able to pay the invoice in multiple currencies!'
    },
    'error': {
        'server_error_contact_support': 'Server error, contact support.',
        'unknown_error_please_contact_support': 'Unknown error, please contact support.',
        'it_is_necessary_to_choose_coin': 'It is necessary to choose a coin.',
        'no_coin_specified': 'No coin specified',
        'you_must_specify_the_amount': 'You must specify the amount',
        'the_amount_is_specified_incorrectly': 'The amount is specified incorrectly',
        'the_amount_cannot_be_less_than': 'The amount cannot be less than or equal to 0',
        'error_webhook_not_specified': 'Error Webhook not specified',
        'invalid_URL_passed_as_Error_Webhook': 'Invalid URL passed as Error Webhook',
        'success_Webhook_is_not_specified': 'Success Webhook is not specified',
        'invalid_URL_passed_as_Success_Webhook': 'Invalid URL passed as Success Webhook',
        'incorrect_expiration_date_of_the_order': 'Incorrect expiration date of the order',
        'empty_output_list': 'Empty output list',
        'the_output_address_is_not_specified': 'The output address is not specified',
        'invalid_output_address': 'Invalid output address',
        'invalid_tag_specified': 'invalid tag(memo) specified',
        'the_withdrawal_amount_is_not_specified': 'The withdrawal amount is not specified',
        'incorrect_withdrawal_amount': 'Incorrect withdrawal amount',
        'the_withdrawal_amount_cannot_be_less_than': 'The withdrawal amount cannot be less than or equal to 0',
        'an_unknown_coin_is_indicated': 'An unknown coin is indicated',
        'insufficient_funds_for_the_operation': 'Insufficient funds for the operation',
        'the_balance_is_less_than_the_requested_amount': 'The balance is less than the requested amount',
        'the_output_is_already_running_wait_for_it_to_finish_and_repeat': 'The output is already running, wait for it to finish and repeat',
        'the_debit_coin_is_not_specified': 'The debit coin is not specified',
        'incorrect_debit_coin': 'Incorrect debit coin',
        'the_transfer_coin_is_not_specified': 'The transfer coin is not specified',
        'incorrect_crediting_coin': 'Incorrect crediting coin',
        'the_amount_of_the_charge_is_not_specified': 'The amount of the charge is not specified',
        'incorrect_debit_amount': 'Incorrect debit amount',
        'the_debit_amount_cannot_be_less_than': 'The debit amount cannot be less than or equal to 0',
        'an_unknown_debit_coin_is_indicated': 'An unknown debit coin is indicated',
        'an_unknown_crediting_coin_is_specified': 'An unknown crediting coin is specified',
        'order_ID_not_specified': 'Order ID not specified',
        'invalid_Order_ID': 'Invalid Order ID',
        'invalid_URL_passed_as_Return_URL': 'Invalid URL passed as Return URL',
        'the_custom_Order_ID_is_too_long': 'The custom Order ID is too long',
        'payment_description_is_too_long': 'Payment description is too long',
        'the_coin_network_is_not_specified': 'The coin network is not specified',
        'incorrect_Coin_network': 'Incorrect Coin network',
        'the_advance_balance_is_not_specified': 'The advance balance is not specified',
        'incorrect_advance_balance': 'Incorrect advance balance',
        'address_not_specified': 'Address not specified',
        'invalid_address': 'Invalid address',
        'no_token_specified_for_output': 'No token specified for output',
        'invalid_token_passed_for_output': 'Invalid token passed for output',
        'insufficient_funds_on_the_advance_balance': 'Insufficient funds on the advance balance',
        'the_amount_is_less_than_the_minimum': 'The amount is less than the minimum',
        'incorrect_coin_network': 'Incorrect Coin network',
        'the_withdrawal_token_has_expired_repeat_the_request_again': 'The withdrawal token has expired, repeat the request again',
        'withdrawal_token': 'The withdrawal token was created for another advance balance or address',
        'the_exchange_is_prohibited': 'The exchange is prohibited',
        'the_coin_is_not_available_for_operations': 'The coin is not available for operations',
        'at_the_moment_the_withdrawal_is_suspended_in_this_coin': 'At the moment, the withdrawal is suspended in this coin',
        'the_network_is_unavailable_for_operations': 'The network is unavailable for operations',
        'the_network_is_undergoing_technical_work': 'The network is undergoing technical work',
        'at_the_moment_the_withdrawal_is_suspended_in_this_network': 'At the moment, the withdrawal is suspended in this network',
        'the_advance_balance_does_not_belong_to_the_current_organization': 'The advance balance does not belong to the current organization',
        'the_address_does_not_belong_to_the_current_user': 'The address does not belong to the current user',
        'the_withdrawal_amount_exceeds_the_available_balance': 'The withdrawal amount exceeds the available balance',
        'error_two-fa': '2FA code is incorrect',
    },
    '2fa-auth-qrcode-modal': {
        'enable_two-factor_authorization': 'Enable Two-factor authorization',
        'remember_the_secret_code': 'Remember the secret code:',
        'connect_two-factor_authentication': 'Connect two-factor authentication using a QR code or insert the code manually inside the application',
        'enter_code_input-label': 'Enter the authentication code after connecting',
        'enter_code_input-placeholder': 'Enter the code...',
        'save_the_backup_codes_for_authorization': 'Save the backup codes for authorization',
        'close': 'Close',
        '2FA_Authorization': 'Two-factor authorization',
        'do_you_want_to_enable_two-factor_authorization': 'Do you want to enable two-factor authorization?',
        'Two-factor_authorization': 'Two-factor authorization',
        'two-factor authorization has been created': 'two-factor authorization has been created',
        'setup_successfully': 'Now you can use 2FA authentication code any time you log in to {productName}',
        'error_when_setting_up_two-factor_authorization': 'error when setting up two-factor authorization',
        'incorrect_code': 'incorrect code',
        'save_codes': 'Save one-time codes, they will be useful to you if there are difficulties with logging in using two-factor authorization',
        'sorry_error': 'Sorry something went wrong, contact support.',
        'access_recovery_keys': 'Access recovery keys',
        'QR_code': 'QR Code',
        'download': 'Download',
        'enable': 'Enable',
        'next': 'Next',
        'invalid_code': 'Invalid code',
        'ok': 'OK',
        'success': 'Success',
        'success_info': 'You have successfully passed two-factor authorization'
    },
    'donation': {
        'title': 'Donation',
        'details': 'List of pages for donations.',
        'orders': 'Orders',
    },
    'donations': {
        'title': 'Donations',
        'create_donation': 'Create donation',
        'table_header': {
            'title': 'Title',
            'total_sum_24h': 'Amount in 24h',
        },
        'details': 'Details',
        'modal': {
            'title': 'Delete donation page',
            'subtitle': 'Do you want to delete',
            'success': 'Successfully deleted',
        },
    },
    'create-donation': {
        'title': 'Create donation',
        'title_edit': 'Edit donation',
        'upload-photo': 'Upload photo',
        'step-1': {title: 'Step 1', description: 'Enter URL, title, select coins'},
        'step-2': {title: 'Step 2', description: 'Confirm and submit'},
        'inputs': {
            'labels': {
                'alias': 'Alias',
                'color': 'Choose color',
                'title': 'Title',
                'description': 'Description',
                'currency_list': 'Coin list',
                'currency_select_all': 'Select all',
                'currency_list_selected': 'Selected',
                'currency_list_selected_out_of': 'out of',
            },
            'placeholders': {
                'alias': 'alias',
                'title': 'Enter title',
                'description': 'Enter description',
            },
            'errors': {
                'title_empty': 'Enter title',
                'currencies_empty': 'Add currency',
            },
        },
        'button': {
            'create_donation': 'Create donation',
            'edit_donation': 'Edit donation',
            'ok': 'Ок',
            'go_back': 'Go back',
        },
        'result': {
            'success': {
                'created': 'Donation page has been successfully created',
                'edited': 'Donation page has been successfully edited',
            },
            'error': {
                'created': 'Something went wrong. Error code {error}',
                'edited_wrong': 'Something went wrong.',
                'edited_unable': 'Unable to edit',
                'edited_code': 'Error code {error}',
            },
        },
    },
    'preCheckout': {
        'errors': {
            'unknown_error': 'Unknown error',
            'fail_to_create_order': 'Fail to create order',
            'external_service_error': 'External service error',
            'token_is_required': 'Token is required',
            'currency_and_network_is_required': 'Currency and network is required',
            'invalid_token': 'Invalid token',
            'page_not_found': 'Page not found',
            'page_not_found_text': 'We can’t seem to find the page you’re looking for',
            'page_not_accepted_deposits_in_currency': 'Currency not accepted for this page',
            'currency_not_allowed_for_deposits': 'Currency not allowed for deposits',
        },
        'new-tab': 'Page for donations payment will be opened in new tab',
        'nothing-happening': 'If nothing happens, press',
        here: 'here',
        'inputs': {
            'labels': {
                'amount': 'Amount',
                'currency': "Currency",
                'alias': 'Email or sender name',
                'commentary': 'Commentary',
            },
            'placeholders': {
                'amount': 'Enter amount',
                'currency': "Choose currency",
                'alias': 'Enter email or sender name',
                'commentary': 'Enter message',
            },
        },
        'button': {
            'payment': 'Go to payment',
        },
        footer: {
            product_2022: 'All rights reserved © {productName} 2023',
        },
    },
    'payout-wallet': {
        'title': 'Payout',
        'subtitle': 'Payout balances list.',
        'button-create-payout': 'Transfer',
        'button-info': 'Details',
        'button-execute-registry': 'Execute',
        'button-replenish-payout': 'Replenish balance',
        'button-open-csv': 'Create registry',
        'currency': 'Currency',
        'withdrawal_amount': 'Withdrawal amount',
        'add-address': 'Add address',
        'native-fee': 'Payment of network fees from the native balance',
        'not-enough-balance': 'Insufficient native balance to pay the fee.',
        'tooltip-transaction': 'The transaction will be executed asynchronously. The result of transaction execution can be tracked in the address history or transaction history.',
        'tooltip-fee': 'The amount is preliminary, the final amount of commission will be after a successful transaction.',
        'fee': {
            'advance': 'Advance balance fee',
            'address': 'Address fee',
        },
        'balance': {
            'network': 'Network',
            'address': 'Address',
            'amount': 'Amount',
            'currency': 'Currency',
            'gas': 'Gas',
            'menu': {
                'info': 'Info'
            }
        },
        'tab': {
            'balance': 'Balances',
            'registry': 'Registries',
            'currency-and-network': 'Currency/Network'
        },
        'modals': {
            'address-info': {
                'title': 'Address Information',
                'labels': {
                    'currency-and-network': 'Currency / Network',
                    'address': 'Address',
                    'auto-swap': 'Use for auto swap by default',
                    'direct-payout': 'Use for direct payout',
                    'alias': 'Alias',
                    'comment': 'Comment'
                }
            }
        }
    },
    'upload_registry': {
        'line': 'Line',
        'error': 'Error',
        'status': {
            'init': 'Initialized',
            'pending': 'Pending',
            'processed': 'Executed',
            'error': 'Error',
            'partial': 'Partially executed',
            'unknown': 'Unknown',
        },
        'errors': {
            'amount_lte_zero': 'Amount is less or equal zero',
            'incorrect_address': 'Incorrect address',
            'incorrect_tag': 'Incorrect tag',
            'currency_not_found': 'Currency not found',
            'fail_to_process_bch': 'Fail to process BCH',
            'payout_address_not_found': 'Payout address not found',
            'invalid_delimiter': 'Invalid delimiter',
            'invalid_amount_of_columns': 'Invalid amount of columns',
            'required_fields_are_empty': 'Required fields are empty',
            'network_not_found': 'Network not found',
            'unknown_error': 'Unknown error',
            'too_many_lines': 'Lines limit exceeded',
        },
    },
    'payout_modal_deposit': {
        'label': 'Currency and network',
        'placeholder': 'Select network and currency',
        'title': 'Adding an address',
        'subtitle': 'Select network and currency to continue',
        'details': 'You can send any amount',
        'alias': 'Alias',
        'comment': 'Comment',
        'save': 'Save',
        'address_for_replenishment': 'Address for replenishment',
        'enter_an_alias': 'Enter an alias',
        'enter_a_comment': 'Enter a comment'
    },
    'payout_modal_upload': {
        'title': 'Create registry',
        'subtitle': 'To continue, enter title select the file to upload',
        'label': 'Enter title',
        'placeholder': 'Title',
        'placeholder-file': {
            'uploaded': 'File uploaded',
            'drag': 'Drag file here',
            'press': 'or press here',
            'drop': 'Drop file here',
        },
        'button': {
            'upload': 'Upload',
            'cancel': 'Cancel',
            'save': 'Save',
        },
    },
    'payout_modal_execute': {
        'title': 'Execute registry',
        'subtitle': 'Execute registry?',
        'pending': 'Registry is pending',
        'success': 'Registry executed successfully',
        'button': {
            'execute': 'Execute',
        },
    },
    'donation-order': {
        'title': 'Donation order',
        'status': 'Status',
        'system_id': 'System ID',
        'amount': 'Amount',
        'currency': 'Currency',
        'network': 'Network',
        'orderId': 'Order ID',
        'pageId': 'Page ID',
        'nickname': 'Nickname',
        'message': 'Message',
        'received_usd': 'Received in USD',
        'received': 'Received',
        'price_usd': ' USD rate',
        'status_enum': {
            'pending': 'Pending',
            'executed': 'Executed',
            'error': 'Error',
            'rejected': 'Rejected',
            'init': 'Active',
        },

    },
    'invoice': {
        'title': 'Invoices ',
        'description': 'Creating an account in a convenient currency and tracking invoice payments.',
        'sum-invoice': 'Invoice amount:',
        'select-invoice': 'Choose payment currency',
        'coin-network': 'Currency and network:',
        'available-currencies-list': 'List of available currencies',
        'select-currencies': 'Select currencies',
        'rate': 'Rate:',
        'sum-payment': 'Payment amount:',
        'button': 'Payment',
        'light': 'Light',
        'dark': 'Dark',
        'account_number': 'Invoice №',
        'account_amount': 'Invoice amount',
        'payment_currency': 'Payment currency',
        'payment_network': 'Payment network',
        'payment_amount': 'Payment amount',
        'exchange_rate': 'Rate',
        'select_invoice_currency': 'Invoice currency...',
        'select_payment_currency': 'Payment currency...',
        'select_payment_network': 'Payment network...',
        'sepa-withdrawal': 'Withdraw SEPA',
        'sepa-deposit': 'Deposit SEPA',
        'fiat-exchange': 'Fiat/Crypto exchange',
        'no_data_available': 'No data available',
        'new_bill': 'New Invoice',
        'status': 'Status',
        'select_the_status': 'Choose status',
        'active': 'Init',
        'in_processing': 'Pending',
        'partial_payment': 'Partial',
        'completed': 'Processed',
        'expired': 'Expired',
        'with_errors': 'With errors',
        'select_currencies': 'Select currencies',
        'currencies': 'Currency',
        'network': 'Network',
        'select_network': 'Select network',
        'period': 'Period',
        'select_dates': 'Select dates',
        'order': 'Order id',
        'invoice': 'Invoice',
        'overpaid': 'Overpaid',
        'currency': 'Currency',
        'received_amount': 'Received amount',
        'date_of_creation': 'Date of creation',
        'error': 'Error',
        'refund': 'Refund',
        'read_more': 'Read more',
        'date_of_creation_order': 'Date of creation',
        'return': 'Return',
        'go_to_invoice': 'Go to invoice',
        'info_network': 'Payment currency and network:',
        'info_title': 'Invoice details',
        'info_sum': 'Invoice amount:',
        'info_rate': 'Rate:',
        'info_pay_sum': 'Payment amount:',
        'info_description': 'Description',
        'info_main_title': 'Invoice',
        'invoice_currency': 'Invoice currency',
        'rejected': 'Rejected',
        'created': 'Created',
        'info_order_amount': 'Order amount:',
        'order_amount': 'Order amount',
        'search': 'Search by id...',
        'validity': 'Expiration date and time',
        'info_btn_label': 'Go to invoice',
        'goto': 'Go to',

        'amount': 'Amount',
    },
    'new_invoice': {
        'details': {
            title: 'Details',
            invoice: 'Invoice',
            date: 'Date',
            description: 'Description'
        },
        'amount': 'Amount',
        'btn_label': 'Continue',
        'cancel': 'Return',
        'search': 'Search...',
        'back_to_shop': 'Back to shop',
        'choose-network': 'Choose network',
        'status': 'Status',
        'you-payed': 'You paid',
        'statuses': {
            'ERROR': 'ERROR',
            'PENDING': 'PENDING',
            'PROCESSED': 'PROCESSED',
            'REJECTED': 'REJECTED',
            'OVERPAID': 'OVERPAID',
            'CREATED': 'CREATED',
            'PARTIAL': 'PARTIAL PAID',
            'INIT': 'INIT',
            'EXPIRED': 'EXPIRED',
        },
        'active_order': 'Active order',
        'payment-successful': 'Payment successful'
    },
    'create-invoice-modal': {
        'invoice': 'Invoice',
        'new_invoice': 'New invoice',
        'invoice_placeholder': 'Invoice №',
        'exchange_rate_percent': '% exchange rate',
        'checkbox_label': 'Network commission on the payer',
        'allowable_price': '% allowable for price slippage',
        'description': 'Description',
        'description_of_order': 'Invoice description',
        'currency': 'Currency',
        'network': 'Network',
        'amount': 'Amount',
        'creating_order': 'Creating invoice',
        'cancel': 'Cancel',
        'create': 'Create',
        'close': 'Close',
        'hours': 'Hours',
        'minutes': 'Minutes',
        'invalid-url': 'Invalid link',
        'invalid-number': 'Invalid number',
    },

    'address_book': {
        'title': 'Address book',
        'sub_title': 'Convenient storage of external addresses for quick access.',
        'cancel': 'Cancel',
        'create': 'Create',
        'close': 'Close',
        'edit': 'Edit',
        'save': 'Save',
        'deleting_an_book_key': 'Deleting an address',
        'delete': 'Delete',
        'are_you_sure': 'Are you sure you want to delete the address',
        'note_line': 'Alias',
        'address_line': 'Address',
        'network_line': 'Network',
        'button': 'Add address',
        'add_modal_title': 'Adding an address',
        'add_modal_placeholder': 'Enter text',
        'add_modal_comment': 'Comment (optional)',
        'change_modal_title': 'Address Information',
        'add_modal_placeholder_address': 'Enter address',
        'add_modal_placeholder_network': 'Choose a network',
    },
    'downCommission': {
        'title': 'Want to lower your fees?',
        'description': 'There are currently maximum commission rates set for your account. To discuss individual conditions and reduce the commission, write to us.',
        'confirm': 'Reduce commissions',
        'cancel': 'I am not interested',
        'congratulations': 'You have created a commission reduction request. We will contact you.',
        'ready': 'Ready',
    },
    'history-addresses': {
        'title': 'История адресов',
    },
    'historyAddresses': {
        'date_of_creation': 'Date',
        'method': 'Institution Method',
        'status': 'Status',
        'basis': 'Base',
        'source': 'Source',
        'coin': 'Currency',
        'network': 'Network',
        'type': 'Type',
        'name': 'Alias',
        'sum': 'Amount',
        'risk': "Risk",
        'transaction': 'ТХ_hash',
        'from_to': 'Address from/to',
        'address': 'Address',
        'pending': 'Expectation',
        'title':  'Transaction (tx hash)',
        'type_transaction': 'Transaction type',
        'write-off_address': 'Address from',
        'transfer_address': 'Address to',
        'write-off_currency': 'Currency',
        'executed': 'Completed',
        'title_main': 'Addresses history',
        'comment': 'Comment',
        'information': 'Transaction Information',
        'input': 'from',
        'conclusion': 'to',
        'withdrawal': 'Withdrawal',
        'deposit': 'Deposit',
        'title_main_current': 'Address history',
        'take_type': 'Select type',
        'search-address-hash': 'Search by address or hash...',
    },
    'business-wallet': {
        'title': 'Business wallet',
        'subtitle': 'Addresses for receiving free deposits without orders and accounts.',
        'header': {
            'search': {
                'placeholder': 'Search by alias, address...'
            },
            'selects': {
                'currency': {
                    'placeholder': 'Currency...'
                },
                'network': {
                    'placeholder': 'Network...'
                }
            },
            'buttons': {
                'archived': 'Archived',
                'zero-balance': 'Zero balance',
                'add-address': 'Add address'
            }
        },
        'table': {
            'columns': {
                'alias': 'Alias',
                'network': 'Network',
                'currency': 'Currency',
                'address': 'Address',
                'amount': 'Amount'
            },
            'buttons': {
                'withdraw': 'Withdraw'
            },
            'row-menu': {
                'info': 'Info',
                'history': 'History',
                'archive': {
                    'to': 'To archive',
                    'from': 'From archive'
                }
            }
        },
        'modals': {
            'archive-toggle-modal': {
                'is-archived': {
                    'title': 'Returning the address from the archive',
                    'info': 'You are returning the address {address} from the archive. This address will be visible in the general list. The address can be archived to avoid seeing it in the general list.'
                },
                'is-not-archived': {
                    'title': 'Sending the address to the archive',
                    'info': 'You send the address {address} to the archive. This address will no longer be visible in the general list. The address can be returned from the archive or you can see all archived addresses with the “archived" command.'
                },
                'buttons': {
                    'close': 'Close',
                    'to-archive': 'Send to archive',
                    'from-archive': 'Return from archive'
                }
            }
        },
        'transaction_hash': 'Transaction hash:',
        'button-info': 'More',
        'button-create-payout': 'Transfer',
        'button-execute-registry': 'Execute',
        'button-replenish-payout': 'Replenish',
        'button-open-csv': 'Create csv',
        'currency': 'Currency',
        'withdrawal_amount': 'Withdrawal amount',
        'amount': 'Amount',
        'address': 'Address',
        'pseudo': 'Alias',
        'success': 'Address has been successfully added',
        'success_update': 'Address has been successfully updated',
        'currency-and-select': 'Currency/Network',
        'close': 'Close',
        'enter-alias': 'Enter alias',
        'fee': {
            'advance': 'Commission from the advance balance',
            'address': 'Commission from the addresses',
        },
        tab: {
            balance: 'Balances',
            registry: 'Register of payments',
        },

        'balance': {
            'network': 'Network',
            'address': 'Address',
            'amount': 'Amount',
            'currency': 'Currency',
        },
        'add-address': 'Add address',
        'comment': 'Comment',
        'add-modal': {
            'title': 'Adding an address',
            'cancel': 'Cancel',
            'confirm': 'Save',
            'continue': 'Continue',
            'creating': 'Adding an address',
            'alias-placeholder': 'Enter the text',
            'comment-placeholder': 'Enter the comment',
        },
        'info-modal-title': 'Address information',
        'empty-currencies': 'No available currencies',
        'added-address-desc': 'You have successfully created a receiving address for {addressString}',
        'added-address-title': 'Address created',
        'added-address-subtitle': 'Full address',
    },
    'not-enough-advance-balance-modal': {
        'title': 'Withdraw is not available',
        'error-text': 'The amount of the advance balance is negative:',
        'btn-label': 'Advance balance',
    },
    'referral': {
        'title': 'Referral program',
        'description': 'Generous and transparent reward program.',
        'active_user': 'Active: ',
        'zero_user': 'Zero: ',
        'buffer_balance': 'Buffer balance',
        'balance': 'Balance',
        'frozen_balance': 'Frozen balance',
        'daily_charges': 'Charges for 24 hours',
        'reward_balance': 'Reward balance',
        'last_reward': 'Last reward',
        'next_reward': 'Next reward',
        'withdrawal': 'Withdrawal',
        'referral': 'Referral',
        'curr_month': 'Current month',
        'total': 'Total',
        'ref_code_copied': 'Referral code copied',
        'ref_link_copied': 'Referral link copied',
        'success-withdraw': 'The withdrawal was successfully completed.',
        'error-withdraw': 'An error occurred during the output. Try again later.',
    },
    'buffer_balance_history': {
        'title': 'History buffer balance',
        'merchant': 'Merchant (email)',
        'select_merchant': 'Select merchant',
        'commission_type': 'Type of commission',
        'select_commission_type': 'Select commission type',
        'type': 'Type',
        'select_type': 'Select type',
        'period': 'Period',
        'deposit': 'Deposit',
        'withdrawal': 'Withdrawal',
        'transfer_fee': 'Transfer fee',
        'percent': 'Percent',
        'sum': 'Sum',
        'date': 'Date',
        'empty': 'No data',
        'accrual': 'Deposit',
        'write-downs': 'Withdrawal',
    },
    'referral-commission-type': {
        'deposit_by_order_fee': 'Accepting payments',
        'wallet_withdrawal_fee': 'Withdrawal from wallet',
        'payout_withdrawal_fee': 'Withdrawal from payout',
        'deposit_by_wallet_fee': 'Wallet deposit',
        'payout_deposit_fee': 'Deposit payout',
        'bridge_external_fee': 'Blockchain bridge API',
        'bridge_internal_fee': 'Blockchain bridge',
        'exchange_internal_fee': 'Swap',
        'exchange_auto_fee': 'Swap API',
        'refund': 'Refund',
        'reward_transfer': 'Reward transfer',
        'recurrent_deposit_fee': 'Recurrent deposit fee',
        'recurrent_withdrawal_fee': 'Recurrent withdrawal fee',
        'personal_deposit_fee': 'Personal address deposit fee',
        'personal_withdrawal_fee': 'Personal address withdrawal fee',
    },
    'reward_balance_history': {
        'title': 'Reward balance history',
        'sum': 'Sum',
        'date': 'Date',
        'type': 'Type',
        'select_type': 'Select type',
        'address': 'Address',
        'transaction': 'Transaction',
        'address-copied': 'Address copied',
        'transaction-copied': 'Transaction hash copied',
    },
    'create-subscription': {
        'title_create': 'New merchant',
        'title_edit': 'Edit merchant',
        'upload': 'Upload image',
        'change': 'Change image',
        'maxSpendAmountUSD': 'Write-off amount',
        'approveAmountUSD': 'Confirmed amount',
        'allowFreeSpend': 'The use of recurrent methods is available',
        'interval': 'Minimum debiting interval',
        'restrictions': 'Data on restrictions',
        '15min': '15 minutes',
        'week': 'Week',
        'day': 'Day',
        'month': 'Month',
        'quarter': 'Quarter',
        'year': 'Year',
        'update': 'Save',
        'inputs': {
            'merchant_name': {
                'label': 'Name',
                'placeholder': "Enter the merchant's name"
            },
            'merchant_image_link': {
                'label': 'Or a link to an image',
                'placeholder': 'Enter link to an image'
            },
        },
        'actions': {
            'cancel': 'Cancel',
            'create': 'Create',
            'save': 'Save',
            'select_file': 'Select a file'
        }
    },
    'subscriptions': {
        'title': 'Payment bundles',
        'description': 'To receive recurring payments, create a merchant account',
        'second-description': 'List and history of payer payment links.',
        'create-bundle': 'Create bundle',
        'create-merchant': 'Create merchant',
        'search-label': 'Email or Ex ID',
        'email': 'Email',
        'token': 'Token',
        'currency': 'Currency',
        'status': 'Status',
        'address': 'Address',
        'network': 'Network',
        'enter_email': 'Search by email or Ex id',
        'success': 'Active',
        'pending': 'Pending',
        'blocked': 'Blocked',
        'canceled': 'Canceled',
        'declined': 'Declined',
        'table': {
            'headers': {
                'email': 'Email',
                'ex_id': 'Ex id',
                'token': 'Token',
                'currency': 'Currency',
                'network': 'Network',
                'address': 'Address',
                'status': 'Status'
            }
        },
        'statuses': {
            'SUCCESS': 'Active',
            'PENDING': 'Pending',
            'BLOCKED': 'Blocked',
            'CANCELED': 'Canceled',
            'DECLINED': 'Declined'
        }
    },
    'subscriptions-addresses': {
        'title': 'Payment addresses',
        'description': 'Addresses for accepting recurring payments',
        'top-bar': {
            'address': {
                'placeholder': 'Search by address...'
            },
            'network': {
                'placeholder': 'Network...'
            },
            'currency': {
                'placeholder': 'Currency...'
            }
        },
        'table': {
            'headers': {
                'amount': 'Amount',
                'currency': 'Currency',
                'network': 'Network',
                'address': 'Address'
            }
        },
        'actions': {
            'history': 'History',
            'withdraw': 'Withdraw'
        },
        "modals": {
            'withdraw': {
                'title': "Withdraw",
                'selects':  {
                    'withdrawal-address': {
                        'label': "Withdrawal address:",
                    },
                    'receiving-address': {
                        'placeholder': "Receiving address:",
                        'label': "Enter address",
                    },
                    'amount': {
                        'placeholder': "0",
                        'label': "Amount",
                    },
                    'Comment': {
                        'placeholder': "Write a description",
                        'label': "Comment",
                    }
                },
                'switcher': {
                    'label': 'Network fee source is sender',
                    'description': 'Description',
                }
            },
            'success': {
                'withdraw': {
                    'title': 'Transaction is pending',
                    'subtitle': 'Transaction hash:',
                },
            },
            'error': {
                'withdraw': {
                    'title': 'Error',
                    'subtitle': 'Internal error',
                    'subtitle-with-request-id': 'Internal error. Request ID:',
                }
            },
            'reject': {
                'withdraw': {
                    'title': 'Withdrawal is not possible',
                    'subtitle': 'The amount of the advance balance is negative:',
                }
            },
            'actions-labels': {
                'close': "Close",
                'create': "Create",
            }
        },
    },
    'upload-avatar': {
        'title': 'Upload image',
        'upload_formats_description': 'You can upload an image in format',
        'upload_formats': 'JPEG, PNG, WebP, GIF',
        'max_file_size_description': 'The maximum allowed file size is',
        'max_file_size': '5 mb',
        'choose_file': 'Select a file',
        'error': 'The file does not meet the requirements'
    },
    'create-bundle': {
        'title': 'Create payment bundle',
        'externalId': 'External Payer ID',
        'email': 'Subscriber`s email',
        'bundle_link': 'Link to bundle',
        'enter_email': 'Enter email',
        'enter_external': 'Enter external id',
    },
    'organization': {
        'organizations_title': 'Organization',
        'organizations_description': 'Management of organization, users, and access roles.',
        'organization': 'Organization',
        'organization_users': 'Users',
        'organization_user': 'User',
        'organization_roles': 'Roles',
        'organization_role': 'Role',
        'organization_last_login': 'Last login',
        'organization_btn_add': 'Add user',
        'organization_btn_add_role': 'Add role',
        'organization_btn_save': 'Save',
        'organization_btn_close': 'Close',
        'organization_btn_transfer': 'Transfer',
        'organization_btn_create': 'Create',
        'organization_btn_back': 'Back',
        'organization_company_name': 'Name',
        'organization_enter_title': 'Enter name',
        'organization_user_and_role': 'User and role',
        'organization_information_role': 'Role information',
        'organization_comment': 'Comment',
        'address_book': 'Address book',
        'advanced_balance': 'Advanced balance',
        'api_keys': 'Api keys',
        'donations': 'Donations',
        'invoices': 'Invoices',
        'orders': 'Orders',
        'payout_balances': 'Payout',
        'transactions': 'Transactions',
        'wallets_business': 'Business wallet',
        'wallets_crosschain_swap': 'Swap',
        'wallets_crosschain_transfer': 'Bridge',
        'wallets_payin': 'Pay-in',
        'organization_enter_input': 'Enter',
        'organization_enter_email': 'Enter an email',
        'organization_enter_name': 'Enter a name',
        'organization_enter_comment': 'Enter comment',
        'modal_user': 'New User',
        'modal_create_user': 'The link for the new user has been successfully created',
        'modal_transfer': 'Transfer',
        'modal_transfer_new_title': 'New owner of the organization:',
        'modal_transfer_success': 'Success',
        'modal_transfer_error': 'Internal error',
        'modal_transfer_error_id': 'Request ID:',
        'modal_transfer_empty': 'There are no users in your organization. Invite the user to your organization.',
        'modal_transfer_desc': 'Select the new owner of the organization. After confirming the transfer of ownership of the organization, you will remain in the role of administrator with full rights.',
        'modal_name': 'Name',
        'modal_take_role': 'Choose a role',
        'modal_take_user': 'Choose a user',
        'modal_email': 'Email',
        'modal_link_invite': 'Invite link',
        'modal_btn_invite': 'Invite',
        'modal_btn_close': 'Close',
        'modal_link_copy': 'Link copied',
        'modal_delete_role': 'Delete role',
        'modal_delete_user': 'Delete user',
        'modal_delete_message': 'Do you really want to delete the role',
        'modal_delete_message_user': 'Do you really want to delete the user',
        'modal_delete_info': 'User information',
        'modal_delete_title': 'Warning note!',
        'organization_no_user_found': 'User not found or not registered',
        'organization_user_already_in': 'The user is already a member of this organization',
        'recurrent': 'Recurrents',
        'personal': 'Personal addresses',
        'webhooks': 'Webhooks',
        'view_webhooks': 'View webhooks',
        'view_dashboard': 'View dashboard',
        'send_webhook_again': 'Send webhook again',
        'view_settings': 'View settings',
        'view_history': 'View history',
        'manage_settings': 'Manage settings',
        'collecting': 'Collecting profits',
        'success': 'Success',
        'success-invite-desc': 'To join your organization, send a link to a new user',
        'manage_address_book': 'Add/Remove an address',
        'update_address_book': 'Update an address',
        'view_address_book': 'View address book',
        'view_advanced_balance_history': 'View advanced balance history',
        'view_analytics': 'View analytics',
        'manage_api_keys': 'Manage API keys',
        'view_api_keys': 'View API keys',
        'manage_donations': 'Manage donations',
        'view_donations': 'View donations',
        'create_invoice': 'Create invoice',
        'view_invoices': 'View invoices',
        'create_order': 'Create order',
        'view_orders': 'View orders',
        'make_withdrawal': 'Make withdrawal',
        'view_transactions': 'View transactions',
        'create_payout_balances': 'Create payout balances',
        'make_transfer': 'Make transfer',
        'view_payout_balances': 'View payout balances',
        'manage_addresses': 'Manage adresses',
        'manage_users': 'Manage users',
        'view_addresses': 'View addresses',
        'view_users': 'View users',
        'create_billing_link': 'Create payment bundle',
        'create_merchant': 'Create merchant',
        'view_billing_links': 'View payment bundles',
        'view_withdrawals': 'View withdrawals',
        'create_business_address': 'Create business-address',
        'manage_business_address': 'Manage business-address',
        'view_wallets': 'View wallets',
        'make_crosschain_swap': 'Make crosschain swap',
        'view_crosschain_swaps': 'View crosschain swap',
        'make_crosschain_transfer': 'Create blockchain bridge',
        'view_crosschain_transfers': 'View blockchain bridge history',
        'create_payin_address': 'Create Pay-in address',
        'orphan': 'Orphan transactions',
        'iframe_widgets': "Widgets",
        'analytics': 'Analytics',
        'bank_accounts': 'Bank',
        'view': 'View',
        'create': 'Create',
        'update': 'Update',
        'delete': 'Delete',
        'modals': {
            'settings': {
                'organization_title': 'Organization',
                'upload_image': 'Upload image',
                'inputs': {
                    'organization_name_input_label': 'Name',
                    'organization_name_input_placeholder': "Enter the organization's name",
                    'organization_link_img_input_label': 'Link to an image',
                    'organization_link_img_input_placeholder': 'Enter link to an image'
                },
                'file_uploader': {
                  'subtitle': 'Click to upload the image',
                  'file_format': {
                      'text': 'You can upload an image in the format',
                      'formats': 'JPEG, PNG, WebP, GIF'
                  },
                  'file_size': {
                      'text': 'Maximum allowed file size',
                      'value': '5 mb'
                  },
                  'drop_text': {
                      'waiting': 'Or drop your image here',
                      'success': 'Your image has been uploaded',
                      'error': 'Your image has not been uploaded. Please try again'
                  }
                },
                'buttons': {
                    'save': 'Save',
                    'close': 'Close',
                    'select_file': 'Select a file'
                }
            }
        },
        'number-of-participants': 'Number of participants',
        'exchanges-bridges': 'Exchanges and bridges',
        'recurring': 'Recurring payments',
        'connect': 'Connect',
        'pass-kyb': 'Pass KYB',
        'members': 'Members',
        'available': 'Available',
        'organization-information': 'Organization information',
        'more': 'More',
        'organization-new-users': 'New added users',
        'organization-new-roles': 'New added roles',
        'history': 'History',
        'disabled': 'Disabled',
        'no-users': 'You don\'t have any users added',
        'no-roles': 'You don\'t have any roles added',
        'passed':'Passed',
        'list': {
          'user': 'User',
          'role': 'Role',
          'last-visit': 'Last visit'
        },
        'save': 'Save changes',
        'organization-form': {
            'website': 'Website',
            'enter-website': 'Enter your website',
            'telegram': 'Owner\'s telegram',
            'enter-telegram': 'Enter owner\'s telegram',
            'email': 'Owner\'s e-mail',
            'enter-email': 'Enter email',
            'time-zone': 'Time zone',
            'choose-time-zone': 'Choose time zone',
            'main-currency': 'Main currency ',
            'choose-main-currency': 'Choose main currency ',
        }
    },
    'rules': {
        'not_allowed': 'No access rights to the section',
    },
    'kyc-modal': {
        'title': 'Warning note!',
        'description': 'Dear Client, kindly note that currently our service is not available for use without passing the mandatory KYB procedure, still you may navigate this webpage to have a closer look at what we may offer you. By accepting this warning you confirm your awareness of current KYB requirements.',
    },
    'info_modals': InfoModals,
    'languages': {
        'select': 'Select language'
    },
    'two-fa-restrictions': {
        'title': 'Action  not available',
        'enableTwoFA': 'Enable 2FA',
        'cancel': 'Cancel',
        'description': 'Action not available without  two-factor authentication.'
    },
    'two-fa-confirmation': {
        'title': 'Two-factor authentication',
        'input-label': 'Enter the code to confirm the action',
        'input-placeholder': 'Enter 2FA code',
        'cancel': 'Cancel',
        'error_length': 'Code must be 6 characters',
        'error_two-fa': '2FA code is incorrect',
        'next': 'Next'
    },
    'orphan': {
        'title': 'Lost transactions',
        'subtitle': 'Search for orphan transactions',
        'where-found': 'Where found',
        'date': 'Date',
        'amount': 'Amount',
        'currency-network': 'Currency/Network',
        'info': 'Transaction information',
        'return': 'Return',
        'transfer-address': 'Transfer address',
        'status-processed': 'Processed',
        'status-error': 'Error',
        'status-pending': 'Pending',
        'status-rejected': 'Rejected',
        'status-init': 'Init',
        'select-status': 'Select status',
        'empty': 'Not found',
        'search': 'Search by hash...',
        'info-btn': 'Information',
        'success-withdrawal': 'The transaction is async. The hash tx will be available in detail in a short time.',
        'withdrawal-comment': 'Withdrawal comment'
    },
    'webhooks': {
        'title': 'Webhooks',
        'subtitle': 'Track payment statuses via the API',
        'date': 'Event date',
        'return': 'Return ',
        'empty': 'Not found',
        'search': 'Search by ID, URL...',
        'info-btn': 'Информация',
        'response-status': 'Response status...',
        'event': 'Event',
        'period': 'Period...',
        'status': 'Status',
        'event-date': 'Last attempt',
        'order': 'Order',
        'invoice': 'Invoice',
        'withdrawal': 'Withdrawal',
        'deposit_orphan': 'Orphan deposit',
        'deposit_personal': 'Personal address deposit',
        'crosschain_transfer': 'Bridge',
        'crosschain_swap': 'Swap',
        'rec_billing_link': 'Payment bundles',
        'rec_subscription': 'Subscription',
        'rec_free_payment': 'Free payment',
        'success': 'Success',
        'redirect': 'Redirect',
        'client-error': 'Client error',
        'server-error': 'Server error',
        'internal-error': 'Internal error',
        'auto_swap': 'Auto swap',
        'auto_withdrawal': 'Auto withdrawal',
        'auto_withdrawal_approve': 'Auto withdrawal approve'
    },
    'webhook-info': {
        'title': 'Webhook information',
        'api-key-alias': 'API key alias',
        'signature': 'Signature',
        'request-headers': 'Request headers',
        'request-body': 'Request body',
        'response-body': 'Response body',
        'send-hook': 'Send the hook again'
    },
    "personal-addresses": {
        "title": "Personal addresses",
        'subtitle': 'Create a list of addresses for each payer individually.',
        "description": "Описание раздела",
        "modals": {
            'add-new-user': {
                'title': "New user",
                'inputs':  {
                    'email': {
                        'placeholder': "Enter email",
                        'label': "Email",
                        'not-valid': "Invalid Email Address",
                        'exist': "This email is occupied by another user",
                    },
                    'external-id': {
                        'placeholder': "Enter id",
                        'label': "External id",
                    },
                },
                'checkboxes': {
                    'create-addresses': {
                        'label': "Create addresses",
                    },
                }
            },
            'add-new-address': {
                'title': "Adding an address",
                'subtitle': "Adding address for {clientEmail}",
                'selects':  {
                    'network': {
                        'placeholder': "Select a network",
                        'label': "Network",
                    },
                    'currency': {
                        'placeholder': "Select a currency",
                        'label': "Currency",
                    }
                }
            },
            'withdraw': {
                'title': "Withdraw",
                'selects':  {
                    'withdrawal-address': {
                        'label': "Withdrawal address:",
                    },
                    'receiving-address': {
                        'placeholder': "Receiving address:",
                        'label': "Enter address",
                    },
                    'amount': {
                        'placeholder': "0",
                        'label': "Amount",
                    },
                    'Comment': {
                        'placeholder': "Write a description",
                        'label': "Comment",
                    }
                }
            },
            'success': {
                'add-user': {
                    'title': 'Success',
                    'subtitle': 'A new user has been successfully created',
                },
                'add-address': {
                    'title': 'Address created',
                    'subtitle': {
                        'first-line': "An address in the currency {currencyNetwork} has been successfully created for the user {clientEmail}",
                        'second-line': "You have successfully created a receiving address:"
                    },
                },
                'withdraw': {
                    'title': 'Transaction is pending',
                    'subtitle': 'Transaction hash:',
                },
            },
            'error': {
                'add-user': {
                    'title': 'Error',
                    'subtitle': 'An error has occurred',
                    'subtitle-with-request-id': 'An error has occurred. Request ID:',
                },
                'add-address': {
                    'title': 'Error',
                    'subtitle': 'An error has occurred',
                    'subtitle-with-request-id': 'An error has occurred. Request ID:',
                },
                'withdraw': {
                    'title': 'Error',
                    'subtitle': 'Internal error',
                    'subtitle-with-request-id': 'Internal error. Request ID:',
                }
            },
            'actions-labels': {
                'close': "Close",
                'create': "Create",
            }
        },
        "buttons": {
            "add-new-user": "Add new user",
            "add-address": "Add address",
            "withdraw": "Withdraw"
        },
        "inputs": {
            "search": {
                "placeholder": {
                    "common": "Search...",
                    "persons":  "Search Email, address, external id..."
                }
            }
        },
        'persons-list': {
            'cols': {
              'email': 'E-mail:',
              'id': 'ID:',
              'external-id': 'External ID:',
              'total-balance': 'Total balance:',
            },
            'empty': {
                'title': 'No users',
                'subtitle': 'Create a user by clicking on'
            }
        },
        'addresses-list': {
            'cols': {
                'address': 'Address:',
                'balance': 'Balance:',
                'currency-network': 'Currency/Network:',
                'status': {
                    'label': 'Status:',
                    'active': 'Active',
                    'inactive': 'Inactive'
                }
            },
            'empty': {
                'title': 'No addresses',
                'subtitle': 'Create an address by clicking on'
            }
        }
    },
    "analytics": {
        'title': 'Analytics',
        'filters': {
            'button-select': {
                'date': 'Date',
                'period': 'Period'
            },
            'grouping': {
                'placeholder': 'Group by...',
                'labels': {
                    'network': 'By network',
                    'wallet': 'By wallet',
                }
            },
            'wallets': {
                'placeholder': 'Wallet...',
                'labels': {
                    'pay_in': 'Pay-in',
                    'personal_address': 'Personal addresses',
                    'business_wallets': 'Business wallets',
                    'pay_out': 'Pay-out',
                    'payment_addresses': 'Payment addresses',
                },
                'select-all': 'Select all',
                'selected-label': 'Selected: {selected} of {length}',
            },
            'date-picker': {
                'btn-label': 'Select',
                'btn-cancel': 'Cancel',
                'placeholder': {
                    'period': 'Period',
                    'date': 'Date',
                }
            },
        },
        'tables': {
            'wallets': {
                'date': {
                    'columns': {
                        'income': 'Income',
                        'outcome': 'Outcome',
                        'balance': 'Balance'
                    },
                    'rows': {
                        'date': 'Date',
                        'currency': 'Currency',
                        'wallet': 'Wallet',
                        'wallets': {
                            'PAY_IN': 'pay-in',
                            'PAY_OUT': 'pay-out',
                            'BUSINESS': 'business',
                            'RECURRENT': 'recurrent',
                            'PERSONAL': 'personal',
                        },
                        'network': 'Network'
                    }
                },
                'period': {
                    'columns': {
                        'sum_start': 'Sum, start',
                        'sum_end': 'Sum, end',
                        'income': 'Income',
                        'outcome': 'Outcome'
                    },
                    'rows': {
                        'period_start': 'Start of period',
                        'period_end': 'End of period',
                        'currency': 'Currency',
                        'wallet': 'Wallet',
                        'wallets': {
                            'PAY_IN': 'pay-in',
                            'PAY_OUT': 'pay-out',
                            'BUSINESS': 'business',
                            'RECURRENT': 'recurrent',
                            'PERSONAL': 'personal',
                        },
                        'network': 'Network',
                    }
                }
            },
            'advanced': {
                'date': {
                    'rows': {
                        'date': 'Date',
                        'balance': 'Balance',
                        'income': 'Income',
                        'outcome': 'Outcome',
                        'blockchain_commission': 'Blockchain commission',
                        'refund': 'Refund',
                        'tariffs': {
                            "ORPHAN_DEPOSIT_WITHDRAWAL_FEE": "Orphan deposit withdrawal fee",
                            "PERSONAL_WITHDRAWAL_FEE": "Personal address withdrawal fee",
                            "PERSONAL_DEPOSIT_FEE": "Personal address deposit fee",
                            "RECURRENT_DEPOSIT_FEE": "Recurrent deposit fee",
                            "PAYOUT_WITHDRAWAL_FEE": "Withdrawals from pay-out fee",
                            "WALLET_WITHDRAWAL_FEE": "Withdrawal from wallet fee",
                            "WALLET_DEPOSIT_FEE": "Wallet deposit fee",
                            "COLLECT_WITHDRAWAL_FEE": "Collect withdrawal",
                            "ORDER_DEPOSIT_FEE": "Order deposit fee",
                            "KYT_WITHDRAWAL_ADDRESS_FEE": "Withdrawal risks",
                            'KYT_TRANSACTION_FEE': 'Transaction risks',
                            'RECURRENT_WITHDRAWAL_FEE': 'Withdrawals from recurrent',
                            'BRIDGE_INTERNAL_FEE': 'Blockchain bridge',
                            'BRIDGE_EXTERNAL_FEE': 'Blockchain bridge API',
                            'EXCHANGE_AUTO_FEE': 'Swap API fee',
                            'EXCHANGE_INTERNAL_FEE': 'Exchange internal fee',
                            'PAYOUT_DEPOSIT_FEE': 'Deposit pay-out',
                            'TRANSFER_FEE': 'Internal transfer',
                            'KYT_ADDRESS_FEE': 'Address risks',
                            'PAYOUT_AUTO_SWAP_FEE': 'Payout + auto swap',
                            'PAYOUT_AUTO_SWAP_FEE_CORRECTION_DECR': 'Correction of the auto swap fee',
                            'PAYOUT_AUTO_SWAP_FEE_CORRECTION_INCR': 'Correction of the auto swap fee'
                        },
                        'networks-fee': {
                            'service': 'Service commission',
                            'network': 'Network commission'
                        }
                    }
                },
                'period': {
                    'rows': {
                        'date_start': 'Date start',
                        'date_end': 'Date end',
                        'balance_start': 'Balance start',
                        'balance_end': 'Balance end',
                        'income': 'Income',
                        'outcome': 'Outcome',
                        'blockchain_commission': 'Blockchain commission',
                        'refund': 'Refund',
                        'tariffs': {
                            "ORPHAN_DEPOSIT_WITHDRAWAL_FEE": "Orphan deposit withdrawal fee",
                            "PERSONAL_WITHDRAWAL_FEE": "Personal address withdrawal fee",
                            "PERSONAL_DEPOSIT_FEE": "Personal address deposit fee",
                            "RECURRENT_DEPOSIT_FEE": "Recurrent deposit fee",
                            "PAYOUT_WITHDRAWAL_FEE": "Withdrawals from pay-out fee",
                            "WALLET_WITHDRAWAL_FEE": "Withdrawal from wallet fee",
                            "WALLET_DEPOSIT_FEE": "Wallet deposit fee",
                            "COLLECT_WITHDRAWAL_FEE": "Collect withdrawal",
                            "ORDER_DEPOSIT_FEE": "Order deposit fee",
                            "KYT_WITHDRAWAL_ADDRESS_FEE": "Withdrawal risks",
                            'KYT_TRANSACTION_FEE': 'Transaction risks',
                            'RECURRENT_WITHDRAWAL_FEE': 'Withdrawals from recurrent',
                            'BRIDGE_INTERNAL_FEE': 'Blockchain bridge',
                            'BRIDGE_EXTERNAL_FEE': 'Blockchain bridge API',
                            'EXCHANGE_AUTO_FEE': 'Swap API fee',
                            'EXCHANGE_INTERNAL_FEE': 'Exchange internal fee',
                            'PAYOUT_DEPOSIT_FEE': 'Deposit pay-out',
                            'TRANSFER_FEE': 'Internal transfer',
                            'KYT_ADDRESS_FEE': 'Address risks',
                            'PAYOUT_AUTO_SWAP_FEE': 'Payout + auto swap',
                            'PAYOUT_AUTO_SWAP_FEE_CORRECTION_DECR': 'Correction of the auto swap fee',
                            'PAYOUT_AUTO_SWAP_FEE_CORRECTION_INCR': 'Correction of the auto swap fee'
                        },
                        'networks-fee': {
                            'service': 'Service commission',
                            'network': 'Network commission'
                        }
                    }
                }
            }
        }
    },
    'closed-session': {
        'title': 'Security message',
        'subtitle': 'Account blocked due to duplicate session',
        'support': 'Contact technical support for details.',
        'contact-support': 'Contact support',
    },
    'common': {
        'empty': 'Empty',
    },
    'common-labels': {
        'placeholders': {
            'input': 'Start typing'
        },
        'select-all': 'Select all',
        'selected-label': 'Selected: {selected} of {length}',
        'back-btn-label': 'Return',
        'wallets': {
            'pay_in': 'Pay-in',
            'personal_address': 'Personal addresses',
            'business_wallets': 'Business wallets',
            'pay_out': 'Pay-out',
            'payment_addresses': 'Payment addresses',
            'main': 'Main addresses',
        }
    },
    'common-components': {
        'inputs': {
          'errors': {
              'empty': 'Empty',
              'e-mail': 'Invalid email format',
          }
        },
        'modals': {
            'success': {
                'title': 'Success',
                'btn': 'Done'
            },
            'error': {
                'title': 'Error',
                'body-text': "Please try again later or contact support",
                'btn': 'Close'
            },
            'warning': {
                'title': 'Warning',
                'body-text': "You have no rights. You can contact our technical support:",
                'btn': 'Ok'
            }
        },
        'date-picker': {
            'btn-label': 'Select',
            'btn-cancel': 'Cancel',
            'placeholder': {
                'period': 'Period',
                'date': 'Date',
            }
        },
        'filter-toggler': {
            'btn-confirm-label': 'Confirm',
            'btn-reset-label': 'Reset',
        },
        'currency-network-select': {
            'label': 'Choose your currency and network',
            'placeholder': 'Choose your currency and network',
            'option': {
                'minimum': 'Minimum',
                'maximum': 'Maximum',
                'available-balance': 'Sum of balances',
            }
        },
    },
    'profits': {
        'title': 'Collecting profits',
        'subtitle': 'Collecting profits from your wallets to one address without commission.',
        'history-title': 'Collecting history',
        'create-title': 'Creating a profit collection',
        'update-title': 'Update a profit collection',
        'history-individual-title': 'History of one collection',
        'hash': 'Hash...',
        'date': 'Date',
        'alias': 'Alias',
        'save': 'Save',
        'save-btn': 'Save',
        'amount': 'Amount',
        'enter-alias': 'Enter alias name',
        'create-setting': 'Create setting',
        'create': 'Create',
        'amount-fee-setup': 'Setting amount',
        'amount-modal': 'Amount, USD',
        'currency-network': 'Currency/Network',
        'address-from': 'Address from',
        'wallet-from': 'Wallet from',
        'address-to': 'Address to',
        'transaction': 'Transaction',
        'status': 'Status',
        'transactions-collected': 'Transactions collected',
        'empty-history-title': 'There is no history',
        'empty-history-subtitle': 'Start collecting profits to fill in the history',
        'select-wallet': 'Select wallet',
        'personal-wallet': 'Personal',
        'personal-wallet-lowercase': 'personal',
        'success': 'Success',
        'success-desc-create': 'successfully created',
        'success-desc-updated': 'successfully updated',
        'success-desc-delete': 'The setting was successfully deleted',
        'error': 'Error',
        'error-conflict': 'Settings conflict',
        'error-internal': 'Internal Error. Request ID:',
        'error-amount': 'Minimum amount is',
        'error-invalid-address': 'Invalid address',
        'close': 'Close',
        'cancel': 'Cancel',
        'empty-settings-title': 'You don\'t have any profit collection settings',
        'empty-settings-subtitle': 'Create a profit collection setting by clicking on create button',
        'delete-title': 'Deleting a setting',
        'delete': 'Delete',
        'delete-subtitle': 'Do you really want to remove the collection setting?',
        'wallet': 'Wallet',
        'collected': 'Collected',
        'address': 'Address',
        'amount-to-collect': 'Amount to collect',
        'history': 'History',
        'edit': 'Edit',
    },
    'auth-history': {
        'title': 'Authorization history',
        'container-title': 'User',
        'date': 'Date',
        'client': 'Client',
    },
    'zero-states': {
        'advanced-balances': {
            'title': 'Top up your balance',
            'subtitle': 'To write off commissions and gas',
            'notifications': {
                'first-line': 'Advance balance is a special balance for paying all transaction fees. This allows you not to take them out of the transaction body, and to operate with exact amounts during transfers.',
                'second-line': 'If the advance balance becomes negative, you can continue to accept payments and transfers, but the withdrawal will be suspended.',
            },
            'actions': {
                'replenishment': {
                    'title': 'Top up',
                    'info': 'You can top up from an external wallet or by transferring funds received from customers from other balances.',
                    'btn-name': 'Top up'
                },
                'api-integration': {
                    'title': "API integration",
                    'info': "Top up your advance balance using the API method.",
                    'btn-name': 'To the method'
                }
            }
        },
        'pay-in': {
            'title': 'Get the first payment',
            'subtitle': 'In this section, your balances will be displayed, to which payments on invoices and orders are credited',
            'notifications': {
                'first-line': "We will automatically create a balance in the client's payment currency and transfer funds to it.",
            },
            'actions': {
                'create-invoice': {
                    'title': "Create an invoice",
                    'info': "Create an invoice for goods and services. The client will pay in a convenient cryptocurrency and network.",
                    'btn-name': 'Go over'
                },
                'create-order': {
                    'title': "Create an order",
                    'info': "Send a ready-made order with the selected currency, network and amount. It is enough for the client to scan the QR code for payment.",
                    'btn-name': 'Go over'
                },
                'api-integration': {
                    'title': "API integration",
                    'info': "Create invoices and orders using the API. You can always write to us for technical assistance.",
                    'btn-name': 'Documentation'
                }
            }
        },
        'invoices': {
            'title': 'Create your first invoice',
            'subtitle': 'All invoices created both manually and via the API will be displayed in this section.',
            'actions': {
                'create-invoice': {
                    'title': "Create an invoice",
                    'info': "Select the currency and the amount to be paid. The client chooses and pays in a convenient cryptocurrency and network.",
                    'btn-name': 'Create'
                },
                'api-integration': {
                    'title': "API integration",
                    'info': "Create invoices using the API. You can always write to us for technical assistance.",
                    'btn-name': 'To the method'
                }
            }
        },
        'orders': {
            'title': 'Create the first order',
            'subtitle': 'All orders created both manually and via the API will be displayed in this section.',
            'actions': {
                'create-order': {
                    'title': "Create an order",
                    'info': "Send a ready-made order with the selected currency, network and amount. It is enough for the client to scan the QR code for payment.",
                    'btn-name': 'Create'
                },
                'api-integration': {
                    'title': "API integration",
                    'info': "Create orders using the API. You can always write to us for technical assistance.",
                    'btn-name': 'To the method'
                }
            }
        },
        'api-keys': {
            'title': 'Your API keys will be displayed here',
            'subtitle': 'In this section, you can create, edit and delete your API keys.',
            'notifications': {
                'first-line': 'Please note: When creating a key, the private key will be shown only once. Keep it in a safe place.',
            },
            'actions': {
                'create-keys': {
                    'title': "Create API keys",
                    'info': "Create an API key to work with the service",
                    'btn-name': 'Create'
                }
            },
            'list-of-available': 'List of available API keys',
            'choose-api-keys': 'Choose API key'
        },
        'business-wallet': {
            'title': 'Create your first business wallet',
            'subtitle': 'All your business wallets will be displayed in this section.',
            'notifications': {
                'first-line': 'Business wallets are cryptocurrency addresses reserved for you to accept payments in different networks and coins.',
            },
            'actions': {
                'create-wallet': {
                    'title': "Create a business wallet",
                    'info': "Receive payments by cryptocurrency transfers directly from customers to their addresses",
                    'btn-name': 'Create'
                },
                'api-integration': {
                    'title': "API integration",
                    'info': "Create business wallets using the API method",
                    'btn-name': 'To the method'
                }
            }
        },
        'subscriptions': {
            'title': 'Create the first payment bundle',
            'subtitle': 'This section will display information about your payment bundles.',
            'notifications': {
                'first-line': "Payment bundles allow you to link subscriber's wallets and your addresses for accepting recurring payments by creating subscriptions.",
            },
            'actions': {
                'create-merchant': {
                    'title': "Create a payment bundle",
                    'info': "Create a payment bundle and receive payments from customers by subscribing to recurring addresses.",
                    'btn-name': 'Create'
                },
                'api-integration': {
                    'title': "API integration",
                    'info': "Create payment bundles using the API. You can always write to us for technical assistance.",
                    'btn-name': 'To the method'
                }
            }
        },
        'pay-out': {
            'title': 'Top up your payout balances to display them',
            'subtitle': 'You do not have any active balances for payments.',
            'notifications': {
                'first-line': 'Top up from an external wallet or transfer funds received from customers.',
            },
            'actions': {
                'create-replenishment': {
                    'title': "Top up from an external account",
                    'info': "Top up your account from an external wallet",
                    'btn-name': 'Create'
                },
                'api-integration': {
                    'title': "API integration",
                    'info': "Create payout balances using the API. You can always write to us for technical assistance.",
                    'btn-name': 'To the method'
                }
            }
        },
        'transactions': {
            'title': 'Make a transaction for the first record in the history',
            'subtitle': 'Receive a payment or top up any account from an external wallet for the first entry in the history to appear.',
            'notifications': {
                'first-line': 'We will display the details of each transaction on your account.',
            },
            'actions': {
                'advanced-balance-replenishment': {
                    'title': "Top up the advance balance",
                    'info': "You can top up from an external wallet or by transferring funds received from customers from other balances.",
                    'btn-name': 'Go over'
                },
                'create-invoice': {
                    'title': "Create an invoice",
                    'info': "Create an invoice for goods and services. The client will pay in a convenient cryptocurrency and network.",
                    'btn-name': 'Go over'
                },
                'create-order': {
                    'title': "Create an order",
                    'info': "Send a ready-made order with the selected currency, network and amount. It is enough for the client to scan the QR code for payment.",
                    'btn-name': 'Go over'
                },
                'create-subscription': {
                    'title': "Create a payment bundle",
                    'info': "Create a payment bundle and receive payments from customers by subscribing to recurring addresses.",
                    'btn-name': 'Go over'
                }
            }
        },
        'address-book': {
            'title': 'Add the first address',
            'subtitle': 'Your saved addresses will be displayed in this section.',
            'notifications': {
                'first-line': 'Save the first address and substitute it when withdrawing funds from the service.',
            },
            'actions': {
                'create-address': {
                    'title': "Save to the address book",
                    'info': "Add and save the address for easy withdrawal of funds",
                    'btn-name': 'Add'
                },
                'api-integration': {
                    'title': "API integration",
                    'info': "Add and save addresses for later use using the API method",
                    'btn-name': 'To the method'
                }
            }
        },
        'orphan-transactions': {
            'title': 'Find the orphan transactions',
            'subtitle': 'All erroneous or orphaned transactions from payers will be found here.',
            'notifications': {
                'first-line': 'If the address belongs to the service, then the payment will definitely not be lost, even if the payer confused the network or currency.',
            }
        },
        'personal-addresses': {
            'title': 'Add the first user',
            'subtitle': 'Create a list of addresses for each payer individually.',
            'actions': {
                'add-user': {
                    'title': "Add a user",
                    'info': "Add and create addresses for your users",
                    'btn-name': 'Add'
                },
                'api-integration': {
                    'title': "API integration",
                    'info': "Create users and addresses for them using API methods",
                    'btn-name': 'To the method'
                }
            }
        },
        'subscriptions-addresses': {
            'title': 'Accept subscription payments',
            'subtitle': 'This section will display your payment addresses for accepting subscription payments.',
            'actions': {
                'create-subscription': {
                    'title': "Create a payment bundle",
                    'info': "Create a payment bundle and receive payments from customers by subscribing to recurring addresses.\n",
                    'btn-name': 'Go over'
                },
                'api-integration': {
                    'title': "API integration",
                    'info': "Create payment bundles using the API. You can always write to us for technical assistance.",
                    'btn-name': 'To the method'
                }
            }
        },
        'bridge': {
            'title': 'Perform bridges between networks',
            'subtitle': 'Simple network change functionality for the convenience of settlements with counterparties',
            'notifications': {
                'first-line': 'Perform bridges in your personal account or using the API method. Bridges are executed asynchronously. About completing bridges accept webhooks.',
            },
            'actions': {
                'api-integration': {
                    'title': "API integration",
                    'info': "Create bridges using the API method.",
                    'btn-name': 'To the method'
                }
            }
        },
        'swap': {
            'title': 'Perform currency swap',
            'subtitle': 'Simple functionality for profit taking',
            'notifications': {
                'first-line': 'Perform swaps in your personal account or using the API method. Swaps are performed asynchronously. On completion of the swaps accept webhooks.',
            },
            'actions': {
                'api-integration': {
                    'title': "API integration",
                    'info': "Create swaps using the API method.",
                    'btn-name': 'To the method'
                }
            }
        },
        'webhooks': {
            'title': 'Track messages',
            'subtitle': 'It is fast and convenient to debug the code during integration',
            'notifications': {
                'first-line': 'The section will display all sent webhooks with all the data in the message. It is also available to resend webhooks.',
            },
            'actions': {
                'api-integration': {
                    'title': "API integration",
                    'info': "Details of sending and recommendations for processing webhooks",
                    'btn-name': 'Documentation'
                }
            }
        },
        'organization': {
            'title': 'Manage the organization',
            'subtitle': 'Invite users to your organization and create roles with individual access settings for each',
            'notifications': {
                'first-line': 'All information about your organization will be displayed in this section',
            },
            'actions': {
                'create-role': {
                    'title': "Create a role",
                    'info': "Create roles for each user with individual access settings",
                    'btn-name': 'Create'
                },
                'add-user': {
                    'title': "Add a user",
                    'info': "Add users to your organization. Please note: before adding a user, you need to create a role.",
                    'btn-name': 'Add'
                },
            }
        },
        'profit-collection': {
            'title': 'Create a profit collection setup',
            'subtitle': 'All created profit collection settings will be displayed in the section',
            'notifications': {
                'first-line': 'Profit collection is created only for Pay-in and Personal wallets. As soon as the amount of at least the amount specified in the profit collection accumulates on the address of the coin and the network specified in the setup, the entire balance of the address is automatically withdrawn to the address also specified in the profit collection setup. At the same time, there is no withdrawal fee.',
            },
            'actions': {
                'create-collection': {
                    'title': "Create a collection",
                    'info': "Create profit collection settings and automatically withdraw the received profit to your address",
                    'btn-name': 'Create'
                }
            }
        }
    },
    'bank': {
        'alias': 'Alias',
        'enter-alias': 'Enter alias',
        'enter-sepa-swift': 'Select SEPA/SWIFT',
        'enter-iban': 'Enter IBAN',
        'wallet': 'Currency',
        'select-wallet': 'Select currency',
        'company': 'Company',
        'enter-company': 'Enter company name',
        'enter-bank-address': 'Enter address',
        'bank-address': 'Bank address',
        'bank': 'Bank',
        'enter-bank': 'Enter bank address',
        'bank-code': 'Bank country code',
        'enter-bank-code': 'Enter code',
        'enter-bic': 'Enter SWIFT/BIC',
        'postal-code': 'Postal code',
        'enter-postal-code': 'Enter postal code',
        'add-invoice': 'Create',
        'deposit': 'Deposit',
        'withdraw': 'Withdrawal',
        'create-account': 'Create account',
        'status': 'Status',
        'type': 'Type',
        'account': 'Account',
        'enter-account': 'Enter account',
        'info-title': 'Account details',
        PRE_MODERATION: 'Created',
        MODERATION: 'Moderation',
        REJECTED: 'Rejected',
        SUCCESS: 'Confirmed',
        'iban-error': 'Invalid IBAN',
        'country-code-error': 'Invalid country code',
        'info': 'Info',
    },
    addressRisks: {
        'white': 'Safe',
        'green': 'Low',
        'yellow': 'Medium',
        'red': 'High',
        'black': 'Severe',
        'unchecked': 'Unchecked',
        'placeholder': 'Risk'
    },
    'iframe-order': {
        'labels': {
            'order': 'Order',
            'currency': 'Currency',
            'e-mail': 'Enter your e-mail address',
            'amount': 'Amount',
            'address': 'Address',
            'paid-amount': 'Paid amount',
            'remaining-time': 'Remaining time to pay'
        },
        'placeholders': {
            'e-mail': 'E-mail'
        },
        'btn': {
            'continue': 'Continue'
        },
        'info': {
            'cookie-policy': {
                'by-clicking': 'By clicking the “Continue” button, you automatically agree to',
                'our': 'our cookie policy',
            },
            'amount': {
                'in-eur': 'Amount in EUR',
                'in-usd': 'Amount in USD',
                'exchange-rate': 'Exchange rate',
                'payment-id': 'Payment ID',
            },
            'payment-history': {
                'title': 'Payment history',
                'date': 'Date',
                'amount': 'Amount',
                'tx-hash': 'Tx_hash',
                'empty': 'Empty'
            },
            'status': {
                'success': 'Payment successful',
                'expired': 'Time is over',
                'wrong-organization-id': 'Wrong organization id',
                'wrong-order-id': 'Wrong order id'
            },
            'popup': {
                'partially-paid': {
                    'title': 'Partially paid',
                    'info': 'The amount has been partially paid. The remaining amount ust be paid for successful payment.'
                },
                'orphan-danger': {
                    'title': 'Orphan transaction',
                    'info': 'One of the transactions on this order contains the wrong network or currency.',
                    'support': 'Contact technical support with ID:'
                }
            }
        }
    },
    'directory-currencies': {
        'page-title': 'Currencies and networks',
        'filters': {
            'placeholders': {
                'currency': 'Currency',
                'network': 'Network',
            },
            'currency-select': {
                'selected-label': '{selected} out of {length} currencies',
            },
            'network-select': {
                'selected-label': '{selected} out of {length} networks',
            }
        }
    },
    'integration-settings': {
        'title': 'Integration settings',
        'elements': {
            'time-picker': {
                'placeholder': 'Choose time'
            },
            'buttons': {
                'labels': {
                    'save': 'Save',
                    'cancel': 'Cancel',
                    'undo': 'Undo'
                }
            },
            'currency-select': {
                'selected-label': '{selected} out of {length} currencies',
            },
            'text-field': {
                'labels': {
                    'name': 'Name',
                    'type': 'Type',
                    'description': 'Description',
                    'value': 'Value'
                }
            },
            'inputs': {
                'placeholders': {
                    'url': 'Enter URL',
                    'percent': 'Enter percent'
                }
            },
            'selects': {
                'placeholders': {
                    'currency': 'Choose currency and network'
                }
            },
            'info-modal': {
                'title': 'Information',
                'body': {
                    'to-configure': 'To configure parameters in API requests, use the template string. The template string allows you to use any parameter passed from the current request, as well as custom parameter names.',
                    'example': 'Example:',
                    'support': 'Suppose you have the following request:',
                    'to-use': 'To use paramFoo in the template string, specify the following:',
                    'on-the-server': 'On the server side, the string will be assembled as follows:',
                    'thus': 'Thus, parameters from the current request will be automatically substituted into the template string, allowing you to flexibly configure API requests.',
                    'important': 'Important!',
                    'if-you': 'If you specify a template with parameters, but do not pass these parameters in the request, we will respond with an error indicating that the parameters must be specified to form the string from the template.'
                }
            }
        },
        'settings': {
            'create-user': {
                'name': 'Creating personal addresses',
                'params': {
                    'description': {
                        'depositWebhookUrl': 'URL for notifications of new replenishments',
                        'createAddresses': 'Create all addresses for the user. The response will return an array of data for the addresses in the addresses parameter',
                        'groupByBlockchain': 'Group addresses by blockchain networks (e.g. 1 address for bsc, fantom, ethereum networks). This parameter has effect only when createAddresses: true',
                    }
                }
            },
            'make-order': {
                'name': 'Create order h2h',
                'params': {
                    'description': {
                        'paymentTolerancePercent': 'Allowable percentage of underpayment. For example, an order for 100 USDT. If the parameter is set as 1.5%, the order will complete successfully with payment of 98.5 USDT',
                        'errorWebhook': 'URL to send a webhook when an error or order expires',
                        'successWebhook': 'URL to send webhook on successful payment',
                        'returnUrl': 'URL to be placed on the payment page as a "Return to Store" link',
                        'lifetime': 'Order lifetime in seconds, available values from 1800 (30 minutes) to 43200 (12 hours)'
                    }
                }
            },
            'make-invoice': {
                'name': 'Create invoice',
                'params': {
                    'description': {
                        'includeFee': 'The flag allows you to include in the amount payable the fee of the blockchain network selected for payment. It will be useful to lay down your costs for withdrawing coins after payment.',
                        'insurancePercent': 'Allows you to add a specified percentage to the payment amount',
                        'slippagePercent': 'When opening the invoice page, the user can spend so much time on it that the exchange rate will change. If after switching to payment the amount changes more than the specified percentage, the amount payable will be recalculated at the current exchange rate',
                        'paymentTolerancePercent': 'Allowable percentage of underpayment of an ORDER. For example, after selecting the invoice coin, an order for 100 USDT was created. If the parameter is set as 1.5%, the order will complete successfully with a payment of 98.5 USDT',
                        'webhookURL': 'URL for notifications when invoice status or amount received changes',
                        'returnURL': 'URL to specify as "Return to Store" on the payment page',
                        'lifetime': 'Invoice lifetime in minutes',
                        'currencies': 'List of coins and networks available for payment, if an empty array is specified, all coins/networks available in the system will be selected'
                    }
                }
            }
        }
    },
    testnet: {
        'testnet-desc': 'organization for integration tests is available',
        'create': 'Create Testnet',
        'redirect': 'Go to',
        'faucet-title': 'Faucet from Onchainpay',
        'faucet-title-apollo': 'Faucet from Apollopayment',
        'faucet-desc': 'If you have not managed to use known Faucet\'s, write to us and we will promptly help you',
        'modal-testnet-error-title': 'Failed to create Testnet',
        'modal-testnet-create-title': 'Testnet created',
        'modal-testnet-error-desc': 'Testnet organization could not be created. Contact technical support or try again later.',
        'modal-testnet-create-desc': 'A new Testnet organization has been successfully created. Navigate using the modal window or by selecting an organization.',
        'go-to-testnet': 'Go to Testnet',
        'close': 'Close',
        'support': 'Support',
        'settings-title': 'Integration settings',
        'settings-desc': 'Customize your settings once and use them in every API request automatically',
    },
    'payments': {
        'total-balance': 'Total balance',
        'available-withdrawal': 'Available for withdrawal',
        'total-increase': 'Total increase',
        'number-orders': 'Number of orders created',
        'personal-deposits': 'Personal deposits',
        'payments-orders': 'Payments by orders',
        'total-personal': 'Number of personal deposits',
        'amount-next-collection': 'Amount of the next collection',
        'dirty-crypto': 'Dirty cryptocurrency',
        'dust': 'Dust',
        more: 'More',
        'next-collection': 'Next collection',
        'frequency-collection': 'Collection frequency',
        'minimum-collection': 'Minimum amount to collect',
        'date-collection': 'at {time} UTC',
        'days': 'days',
        'short-days': 'days',
        'day': 'day',
        'currency': 'Currency',
        'network': 'Network',
        'expand': 'Expand',
        'cancel': 'Cancel',
        'confirm': 'Confirm',
        'withdraw': 'Withdraw',
        'swap-confirmation-title': 'Swap <br> confirmation',
        'swap-title': 'Swap <br> confirmation',
        'swap': 'Swap',
        'dirty-tooltip-title': 'Dirty Cryptocurrency',
        'risk-tooltip-title': 'Risks',
        'risk-tooltip-desc': 'An identifier for the use of digital assets for illegal activities.',
        'dirty-tooltip-desc': 'It is a cryptocurrency that has been used or suspected of being used for illegal activities.',
        'dust-tooltip-title': 'Dust',
        'dust-tooltip-desc': 'This amount of coins whose value doesn\'t even cover transaction fees and are difficult to use.',
        'zero-state': {
            'all-balances': 'All balances in one place',
            'get-payments': 'Accept payments to different addresses and manage funds in one place',
            'total-balance': 'Total balance by currency and network',
            'swap-click': 'Swaps in a couple of clicks',
            'more': 'Detailed analytics for each balance sheet',
            'main-addresses': 'Head addresses',
            'enable-access': 'Enable full access to the head address functionality with a single button',
            'enable-aml': 'Enable AML',
            'enable': 'Enable',
            'convenient': 'Convenient and intuitive tracking of payment risk by currency or network.'
        }
    },
    'info-banners': {
        'migration': 'Please be notified that we have <b>migrated from MATIC to POL</b>, all your assets have been automatically migrated'
    },
    'widgets': {
        title: "Widgets",
        addWidget: "Add widget",
        sumOfDeposits: "Sum of deposits",
        countOfDeposits: "Count of deposits",
        yourSiteUrl: 'Your Site URL',
        siteUrl: 'Site URL',
        enterSiteUrl: 'Enter site URL',
        payin: "Pay in",
        payout: "Pay out",
        invoices: "Invoices",
        personal: "Personal addresses",
        active: "Active",
        disabled: "Disabled",
        demo: "Demo",
        demoMode: "Demo mode",
        demoModeDesc: "To test the widget, fill in the dynamic data and follow the link.",
        edit: "Edit",
        return: 'Return',
        back: 'Back',
        depositWidget: 'Deposit widget',
        payoutWidget: 'Withdraw widget',
        copyLink: 'Copy',
        copied: 'Copied!',
        goto: 'Go to',
        widgetAlias: 'Widget alias',
        deposit: 'Deposit',
        withdraw: 'Withdraw',
        startPageLocked: 'When selecting a direct link, there is no window',
        startPage: 'Start page',
        oneOption: '(select one option)',
        startPageDesc: 'With the start page, your payer will be able to choose their own deposit or withdrawal without unnecessary integration on your part.',
        directLink: 'Direct link',
        directLinkDesc: 'The created link will enter directly into the selected functionality.',
        paymentAccept: 'Payment Acceptance',
        personalDesc: 'Allows you to generate a list of addresses for each payer individually. The addresses are assigned to the payer.',
        invoicesDesc: 'Accepting payment via invoicing. You or your payer can specify the invoice currency (including fiat) and amount and pay in one of the cryptocurrencies',
        payoutDesc: 'The functionality allows you to make transfers to your user\'s external addresses.',
        freePayment: 'Free Payment',
        commissionOnPayer: 'Commission on the payer',
        apiKey: 'API Key',
        allowablePercent: 'Allowable % on exchange rate',
        allowableSlippage: 'Allowable % for price slippage',
        allowableUnderpayment: 'Allowable % for underpayment of invoice',
        enterAllowableUnderpayment: 'Enter the allowable % of invoice underpayment',
        enter: 'Enter',
        enterWebhook: 'Enter webhook',
        paymentCurrency: 'Payment currency',
        payoutCurrency: 'Payout currency',
        invoiceCurrency: 'Invoice currency',
        approvalWebhook: 'Approval webhook',
        enterApprovalWebhook: 'Enter approval webhook',
        enterAllowablePercent: 'Enter allowable % on exchange rate',
        enterSlippagePercent: 'Enter allowable  % for price slippage',
        lockedAmount: 'The option is disabled. Select only one invoice currency to customize',
        invoiceAmount: 'Invoice amount',
        payoutAmount: 'Payout amount',
        orderId: "Invoice ID",
        description: "Description",
        email: "Email address",
        theme: "Theme",
        lightTheme: 'Light theme',
        darkTheme: 'Dark theme',
        lockedFreePayment: 'The parameter is off. To set it, turn off “free payment”',
        lockedEmail: 'The parameter is off. To configure it, turn on the “E-mail field”',
        emailEnabled: 'The E-mail input field is available',
        emailRequired: 'E-mail is required',
        warning: 'Warning note!',
        warningDeleteDesc: 'Do you really want to <span class="desc-accent">delete widget?</span>',
        delete: 'Delete',
        noApiKeys: 'You are missing API keys',
        noApiKeysDesc: 'Before creating the widget, create an API key in the “API keys” section',
        createApiKey: 'Create API key',
        next: 'Next',
        end: 'Complete',
        enterOrderId: 'Enter order ID',
        enterSum: 'Enter amount',
        enterEmail: 'Enter e-mail address',
        enterDescription: 'Enter description',
        paymentButtonTag: "Payment button tag",
        iframeIntegrationLink: "Link for iframe integration",
        paymentButtonScript: "Script for payment button",
        iframeIntegrationLinkDesc: "Insert the link into your <iframe> HTML tag.",
        paymentButtonScriptDesc: "To install the widget it is necessary to write a script in the head section of the site.",
        paymentButtonDesc: "To display the payment button, insert the button's HTML tag in the desired location on the page where you want to place it.",
        receiveCodeDesc: "Payin code",
        payoutCodeDesc: "Payout code",
        buttonPreview: "Button preview",
        buttonPreviewDesc: "Enter the label you want to see on the button",
        enterButtonLabel: "Enter the button label",
        mainStyle: "Main style",
        or: "Or",
        tooltip: {
            description: 'A free field with a description for the payer.',
            email: 'The parameter is responsible for sending Email to the widget in the format email@example.com.',
            orderId: 'Your unique payment identifier.',
            invoiceAmount: 'Set the amount for your account. The field is only available with one selected account currency',
            siteUrl: 'Insert the link of the site where the widget will be used. Format: https://www.service.com/',
            apiKey: 'For the widget to work, you need to select the API key. If it is not created, create it in a separate tab.',
            webhook: 'To interact with the widget and accept payment messages, you need to provide a link to accept webhooks. Format: https://www.webhook.com/',
            freePayment: 'Enable this option if you want to accept payment without a specific currency and amount. Only the payment currencies remain to be selected',
            commissionOnPayer: 'Choose who will pay the transaction fee - you or your payer.',
            allowablePercent: 'Additional percentage added to the exchange rate from the invoice currency to the payment currency. Default setting is 0',
            allowableSlippage: 'Allowable value of price change as a percentage of the payment currency when volatility is high. Default setting is 0',
            underpayment: 'Allowable value for underpayment of invoice',
            invoiceCurrency: 'Available invoice currencies. Cryptocurrency and fiat available',
            paymentCurrency: 'Available currencies for cryptocurrency payments.',
            emailAllowed: 'The parameter is responsible for displaying the Email field in the widget.',
            emailRequired: 'The parameter is responsible for the necessity to enter the payer\'s Email when making a payment',
            approvalWebhook: 'The URL where we will send confirmation webhooks for payout processing',
            payoutCurrency: 'Specifies the payout currency (not the payment currency). Can be a single currency (USD) or an array (USD, USDT, EUR, RUB, USDT, ETH).',
        }
    },
    buyCrypto: {
        title: 'Buy crypto'
    }
};
